import React from 'react';
import PropTypes from 'prop-types';
import RpaParametersList from "../RpaParametersList";
import { Grid } from "@mui/material";
import { socket } from "../../socket";
import { CircularProgress } from "@mui/material";

function RpaParameters(props) {
    const handleChangeParametrosInicioCb = (parametros_inicio) => {
        if (props.rpaData !== null && props.rpaData !== undefined && props.rpaData.doc_id !== undefined) {
            socket.emit("request_update_rpa", { 'doc_id': props.rpaData.doc_id, 'parametros_inicio': parametros_inicio });
        }
    };

    return (
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
            <Grid item xs={12} align="center">
                {(props.rpaData === null || props.rpaData === undefined) ?
                    <CircularProgress />
                :
                    <RpaParametersList
                        parametros_inicio={props.rpaData.parametros_inicio}
                        setParametrosInicioCb={handleChangeParametrosInicioCb}
                    />
                }
            </Grid>
        </Grid>
    );
}

RpaParameters.propTypes = {
    rpaData: PropTypes.object
};

export default RpaParameters;