import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import PermissionList from "../PermissionList";
import Loader from "../Loader";
import { socket } from "../../socket";

import {
    Dialog,
    DialogTitle,
    Typography,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Grid,
    MenuItem,
    Checkbox,
    Select,
    InputLabel,
    FormControl,
} from "@mui/material";

function AddEditUserDialog(props) {
    const [userEmail, setUserEmail] = useState("");
    const [userPassword, setUserPassword] = useState("");
    const [userEmpresa, setUserEmpresa] = useState("default");
    const [userRolesList, setUserRolesList] = useState([]);
    const [userHabilitado, setUserHabilitado] = useState(true);
    const [loading, setLoading] = useState(false);
    const AllRolesList = ["admin"]

    useEffect(() => {
        function fetchRpaData() {
            if (props.userData !== undefined) {
                if (props.userData.user_email !== undefined && props.userData.user_email !== null)
                    setUserEmail(props.userData.user_email);

                if (props.userData.empresa !== undefined && props.userData.empresa !== null)
                    setUserEmpresa(props.userData.empresa);

                if (props.userData.roles !== undefined && props.userData.roles !== null)
                    setUserRolesList(props.userData.roles);

                if (props.userData.habilitado !== undefined && props.userData.habilitado !== null)
                    setUserHabilitado(props.userData.habilitado);
            }
        }

        fetchRpaData();
    }, [props.userData]);

    const handleCadastrarUsuario = async () => {

        if (userEmail.length < 4)
            return props.openSnackbar(`E-mail inválido.`);

        if (props.userData === undefined && userPassword.length < 6)
            return props.openSnackbar(`Senha inválida.`);

        if (userEmpresa === "default")
            return props.openSnackbar(`Selecione a empresa do usuário.`);

        let objUser = {
            uid: props.userData ? props.userData.uid : undefined,
            email: userEmail,
            password: userPassword,
            habilitado: userHabilitado,
            roles: userRolesList,
            empresa: userEmpresa
        }
        
        setLoading(true);
        if (props.userData === undefined) {
            socket.emit("request_create_user", objUser, (response) => {
                if (response) {
                    props.openSnackbar(`Usuário ${userEmail} criado.`);
                }

                setLoading(false);
            });
        } else {
            socket.emit("request_update_user", objUser, (response) => {
                if (response) {
                    props.openSnackbar(`Usuário atualizado.`);
                }

                setLoading(false);
            });
        }

        handleClose();
    };

    const handleClose = () => {
        setUserEmail("");
        setUserEmpresa("");
        setUserRolesList([]);
        setUserHabilitado(true);
        setLoading(false);
        props.handleClose(false);
    };

    // empresas select
    let elements_all_empresas = [];
    if (props.allEmpresasList !== undefined) {
        for (let empresa of props.allEmpresasList) {
            elements_all_empresas.push(
                <MenuItem key={empresa.doc_id} value={empresa.doc_id}>{empresa.nome}</MenuItem>
            );
        }
    }

    // loading
    if (loading) {
        return (
            <Loader />
        );
    }

    return (
        <Dialog fullWidth maxWidth="md" open={props.open}>
            <DialogTitle>
                {props.userData === undefined ?
                    "Novo Usuário"
                    :
                    `Atualizar Usuário: ${userEmail}`
                }
            </DialogTitle>

            <DialogContent sx={{ m: 1, p: 1 }}>
                <Grid container justifyContent="center" alignItems="center" spacing={2}>

                    <Grid item xs={12}>
                        <Grid container justifyContent="center" alignItems="center" spacing={1}>
                            <Grid item>
                                <Typography sx={{ m: 0, p: 0 }}>Habilitado: </Typography>
                            </Grid>
                            <Grid item>
                                <Checkbox
                                    sx={{ m: 0, p: 0 }}
                                    checked={userHabilitado}
                                    onChange={(event) => setUserHabilitado(event.target.checked)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            disabled={props.userData === undefined ? false : true}
                            fullWidth
                            label="E-mail"
                            variant="filled"
                            color="secondary"
                            value={userEmail}
                            type="email"
                            onChange={(event) => setUserEmail(event.target.value)}
                        />
                    </Grid>
                    
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Senha"
                            variant="filled"
                            color="secondary"
                            value={userPassword}
                            type="password"
                            onChange={(event) => setUserPassword(event.target.value)}
                        />
                    </Grid>

                    <Grid item align="center" xs={6}>
                        <FormControl sx={{ width: '100%', p: 0, m: 0 }}>
                            <InputLabel id="select-empresa">Empresa</InputLabel>
                            <Select
                                fullWidth
                                labelId="select-empresa"
                                id="select-empresa"
                                label="Empresa"
                                value={userEmpresa}
                                onChange={(event) => setUserEmpresa(event.target.value)}
                            >
                                <MenuItem disabled value={"default"}>Selecione</MenuItem>
                                {elements_all_empresas}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid align="center" item xs={6}>
                        <PermissionList permitidosList={userRolesList} allList={AllRolesList.map(el => { return { "label": el, "value": el } })} label="Roles" setPermitidosListCb={setUserRolesList} />
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Fechar
                </Button>
                <Button onClick={() => handleCadastrarUsuario()} color="primary">
                    {props.userData === undefined ?
                        "Cadastrar"
                        :
                        "Atualizar"
                    }
                </Button>
            </DialogActions>
        </Dialog>
    );
}

AddEditUserDialog.propTypes = {
    user: PropTypes.object,
    openSnackbar: PropTypes.func,
    userData: PropTypes.object,
    allEmpresasList: PropTypes.array
};

export default AddEditUserDialog;