import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import Loader from "../Loader";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import makeStyles from "@mui/styles/makeStyles";
import ConfirmationDialog from "../ConfirmationDialog";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import clsx from 'clsx';
import AddEditEmpresaDialog from "./AddEditEmpresaDialog"
import { socket } from "../../socket";

import {
	Grid,
	Paper,
	Fab,
	Typography,
	Box,
	Divider,
	Chip,
	Button
} from "@mui/material";

const useStyles = makeStyles({
	paperList: {
		width: '100%',
		textAlign: "center",
		display: "flex"
	},
	bgDefault: {
		background: 'linear-gradient(45deg, rgba(15, 150, 0, 0.3) 30%, rgba(15, 150, 0, 0.3) 90%)',
		boxShadow: '0px 0px 5px 2px rgba(11, 92, 2, .4)',
	}
});

function AdminEmpresas(props) {
	const [empresasList, setEmpresasList] = useState([]);
	const [dialogAddEmpresaOpen, setDialogAddEmpresaOpen] = useState(false);
	const [dialogDeleteEmpresaOpen, setDialogDeleteEmpresaOpen] = useState(false);
	const [loading, setLoading] = useState(true);
	const [selectedEmpresaData, setSelectedEmpresaData] = useState(undefined);
	const classes = useStyles();

	useEffect(() => {
		setLoading(true);
		socket.emit("request_get_all_empresas", (args) => {
			if (args !== null && args !== undefined) {
				setEmpresasList(JSON.parse(args));
				
			}
			setLoading(false);
		});

		console.log('update AdminEmpresas')
	}, []);

	const handleOpenCloseDialogAddEmpresa = (state) => {
        setSelectedEmpresaData(undefined);
        setDialogAddEmpresaOpen(state);
    }

	const handleOpenCloseDialogEditEmpresa = (state, empresaData) => {
        setSelectedEmpresaData(empresaData);
        setDialogAddEmpresaOpen(state);
    }

	const handleOpenCloseDialogDeleteEmpresa = (state, empresaData) => {
		setSelectedEmpresaData(empresaData);
        setDialogDeleteEmpresaOpen(state);
    }

	const handleDeleteEmpresa = async (empresaData) => {
		socket.emit("request_delete_empresa", { "doc_id": empresaData.doc_id }, (res) => {
			if (res) 
			{
				props.openSnackbar(`Empresa ${empresaData.nome} removida.`);
			}
			setDialogDeleteEmpresaOpen(false);
		});
    }

	// empresas infos
	let elements_empresas = [];
	for (let [idx, empresaData] of empresasList.entries()) {
		elements_empresas.push(
			<Grid item key={empresaData.nome} xs={8} sm={3}>
				<Paper elevation={3} square={true} sx={{ p: 1 }} className={clsx(classes.paperList, classes.bgDefault)}>
					<Grid container justifyContent="center" alignItems="center" spacing={0}>
						<Grid item xs={12} md={10}>
							<Typography variant="subtitle2" component="div" sx={{ textTransform: 'uppercase' }}>
								<Box sx={{ textAlign: 'start', boxShadow: 0, m: 0, p: 1, fontWeight: 'bold' }}>
									CNPJ: <Chip sx={{ fontWeight: 'normal', maxWidth: '80%' }} label={empresaData.cnpj ? empresaData.cnpj : "N/A"} size="small" variant="outlined" />
								</Box>
								<Divider variant="middle" />
								<Box sx={{ textAlign: 'start', boxShadow: 0, m: 0, p: 1, fontWeight: 'bold' }}>
									NOME: <Chip sx={{ fontWeight: 'normal', maxWidth: '80%' }} label={empresaData.nome} size="small" variant="outlined" />
								</Box>
							</Typography>
						</Grid>

						<Grid item xs={12} md={2}>
							<Grid container justifyContent="center" alignItems="center" direction="column" spacing={2}>
								<Grid item>
									<Fab size="small" color="secondary" aria-label="edit" >
										<EditIcon onClick={() => handleOpenCloseDialogEditEmpresa(true, empresasList[idx])}/>
									</Fab>
								</Grid>
								<Grid item>
									<Fab size="small" color="error" aria-label="edit" onClick={() => handleOpenCloseDialogDeleteEmpresa(true, empresasList[idx])}>
										<DeleteForeverIcon />
									</Fab>
								</Grid>
							</Grid>
						</Grid>

					</Grid>
				</Paper>
			</Grid>
		);
	}

	if (loading) {
		return (
			<Loader />
		);
	}

	return (
		<React.Fragment>
			<ConfirmationDialog
				dialogProps={{
					open: dialogDeleteEmpresaOpen,
					onClose: () => handleOpenCloseDialogDeleteEmpresa(false),
				}}
				content={<Typography variant="subtitle1" component="div" sx={{ textTransform: 'uppercase', m: 1 }}>Deseja remover a empresa {selectedEmpresaData?.nome} ?</Typography>}
				dismissiveAction={<Button color="primary" onClick={() => handleOpenCloseDialogDeleteEmpresa(false, undefined)}>Cancelar</Button>}
				confirmingAction={<Button color="warning" onClick={() => handleDeleteEmpresa(selectedEmpresaData)}>Sim</Button>}
			/>

			<AddEditEmpresaDialog
                open={dialogAddEmpresaOpen} 
                handleClose={handleOpenCloseDialogAddEmpresa}
                openSnackbar={props.openSnackbar}
                user={props.user}
                empresaData={selectedEmpresaData}
            />
			
			<Grid container justifyContent="center" alignItems="center" spacing={2}>
				<Grid item xs={12} sm={12}>
					<Box sx={{ mt: 2, mb: 1, textAlign: 'center' }}>
						<Fab color="primary" aria-label="add" onClick={() => handleOpenCloseDialogAddEmpresa(true, undefined)}>
							<AddIcon />
						</Fab>
					</Box>
				</Grid>

				<Grid item xs={12} sm={12}>
					<Grid container justifyContent="center" alignItems="center" spacing={2} sx={{ p: 2 }}>
						{elements_empresas}
					</Grid>
				</Grid>
			</Grid>
		</React.Fragment>
	);
}


AdminEmpresas.propTypes = {
	openSnackbar: PropTypes.func.isRequired,
};

export default AdminEmpresas;
