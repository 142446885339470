import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
    Dialog,
    Typography,
    AppBar,
    Toolbar,
    IconButton,
    Slide,
    DialogContent,
    DialogTitle,
    Grid
} from "@mui/material";

import PropTypes from "prop-types";
import RpaLogs from "./RpaLogs.js"
import RpaActions from "./RpaActions.js"
import RpaProcessamentos from "./RpaProcessamentos.js"
import RpaParameters from "./RpaParameters.js"

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function RpaDialog(props) {
    const [rpaData, setRpaData] = useState(undefined);

    useEffect(() => {
        setRpaData(props.rpaData);
        console.log('update RpaDialog')
    }, [props.rpaData]);

    const handleClose = () => {
        setRpaData(undefined);
        props.handleClose(false, undefined);
    };
    
    return (
        <Dialog
            fullScreen
            open={props.open}
            TransitionComponent={Transition}
        >   
            <DialogTitle sx={{ m: 1, p: 0 }}>
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>

                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            {rpaData?.rpa_identity}
                        </Typography>
                    </Toolbar>
                </AppBar>
            </DialogTitle>

            <DialogContent sx={{ m: 1, p: 1 }}>
                <Grid container justifyContent="center" alignItems="center" spacing={4}>
                    <React.Fragment>
                        <Grid item xs={12}>
                            <RpaActions rpaData={rpaData} openSnackbar={props.openSnackbar}/>
                        </Grid>
                        <Grid item xs={12}>
                            <RpaParameters rpaData={rpaData}/>
                        </Grid>
                        <Grid item xs={12}>
                            <RpaLogs rpaData={rpaData} />
                        </Grid>
                        <Grid item xs={12}>
                            <RpaProcessamentos rpaData={rpaData} />
                        </Grid>
                    </React.Fragment>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

RpaDialog.propTypes = {
    openSnackbar: PropTypes.func.isRequired,
    rpaData: PropTypes.object
};

export default RpaDialog;