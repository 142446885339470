import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import Loader from "../Loader";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import makeStyles from "@mui/styles/makeStyles";
import AddEditUserDialog from "./AddEditUserDialog"
import clsx from 'clsx';
import { socket } from "../../socket";

import {
	Grid,
	Paper,
	Fab,
	Typography,
	Box,
	Divider,
	Chip
} from "@mui/material";

const useStyles = makeStyles({
	paperList: {
		width: '100%',
		textAlign: "center",
		display: "flex"
	},
	bgDefault: {
		background: 'linear-gradient(45deg, rgba(15, 150, 0, 0.3) 30%, rgba(15, 150, 0, 0.3) 90%)',
		boxShadow: '0px 0px 5px 2px rgba(11, 92, 2, .4)',
	}
});

function AdminUsers(props) {
	const [usersList, setUsersList] = useState(undefined);
	const [userSelectedData, setUserSelectedData] = useState(undefined);
	const [dialogAddUserOpen, setDialogAddUserOpen] = useState(false);
	const [allEmpresasList, setAllEmpresasList] = useState([]);
	const [loading, setLoading] = useState(true);
	const classes = useStyles();

	const handleOpenCloseDialogAddUser = (state) => {
        setUserSelectedData(undefined);
        setDialogAddUserOpen(state);
    }

    const handleOpenCloseDialogEditUser = (state, userData) => {
        setUserSelectedData(userData);
        setDialogAddUserOpen(state);
    }

	useEffect(() => {
		setLoading(true);
		socket.emit("request_get_all_users", (response) => {
			if (response) {
				setUsersList(JSON.parse(response));
			}
			setLoading(false);
		});

		setLoading(true);
		socket.emit("request_get_all_empresas", (response) => {
			if (response) {
				setAllEmpresasList(JSON.parse(response));
			}
			setLoading(false);
		});
		
		console.log('update AdminUsers');
	}, []);

	let elements_users = [];
	if (usersList !== undefined && usersList !== null) {
		for (let [idx, userData] of usersList.entries()) {
			let elements_roles = [];

			if (userData.roles) {
				for (let role of userData.roles) {
					if (role.length > 0) {
						elements_roles.push(
							<Chip key={"role"+userData.email+role} sx={{ fontWeight: 'normal' }} label={role} size="small" variant="outlined" />
						);
					}
				}
			}

			elements_users.push(
				<Grid item key={userData.email} xs={6} sm={3}>
					<Paper elevation={3} square={true} sx={{ p: 1 }} className={clsx(classes.paperList, classes.bgDefault)}>
						<Grid container justifyContent="center" alignItems="center" spacing={0}>
							<Grid item xs={12} md={10}>
								<Typography variant="subtitle2" component="div" sx={{ textTransform: 'uppercase' }}>
									<Box sx={{ textAlign: 'start', boxShadow: 0, m: 0, p: 1, fontWeight: 'bold' }}>
										USER: <Chip sx={{ fontWeight: 'normal' }} label={userData.user_email} size="small" variant="outlined" />
									</Box>
									<Divider variant="middle" />
									<Box sx={{ textAlign: 'start', boxShadow: 0, m: 0, p: 1, fontWeight: 'bold' }}>
										HABILITADO: <Chip sx={{ fontWeight: 'normal' }} label={userData.habilitado ? "Sim" : "Não"} size="small" variant="outlined" />
									</Box>
									<Divider variant="middle" />
									<Box sx={{ textAlign: 'start', boxShadow: 0, m: 0, p: 1, fontWeight: 'bold' }}>
										ROLES: {elements_roles.length > 0 ? elements_roles : <Chip key={"role" + userData.email + "user"} sx={{ fontWeight: 'normal' }} label={"User"} size="small" variant="outlined" />}
									</Box>
								</Typography>
							</Grid>
							<Grid item xs={12} md={2}>
								<Grid container justifyContent="center" alignItems="center" direction="column" spacing={2}>
									<Grid item>
										<Fab size="small" color="secondary" aria-label="edit" >
											<EditIcon onClick={() => handleOpenCloseDialogEditUser(true, usersList[idx])}/>
										</Fab>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Paper>
				</Grid>
			);
		}
	}

	if (loading) {
		return (
			<Loader />
		);
	}

	return (
		<React.Fragment>
			<AddEditUserDialog
                open={dialogAddUserOpen} 
                handleClose={handleOpenCloseDialogAddUser}
                openSnackbar={props.openSnackbar}
                user={props.user}
                allEmpresasList={allEmpresasList}
                userData={userSelectedData}
            />

			<Grid container justifyContent="center" alignItems="center" spacing={2}>
				<Grid item xs={12} sm={12}>
					<Box sx={{ mt: 2, mb: 1, textAlign: 'center' }}>
						<Fab color="primary" aria-label="add" onClick={() => handleOpenCloseDialogAddUser(true, undefined)}>
							<AddIcon />
						</Fab>
					</Box>
				</Grid>
				<Grid item xs={12} sm={12}>
					<Grid container justifyContent="center" alignItems="center" spacing={2} sx={{ p: 2 }}>
						{elements_users}
					</Grid>
				</Grid>
			</Grid>
		</React.Fragment>
	);
}


AdminUsers.propTypes = {
	user: PropTypes.object.isRequired,
    openSnackbar: PropTypes.func.isRequired
};

export default AdminUsers;
