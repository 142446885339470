import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import SearchIcon from '@mui/icons-material/Search';

import {
	FormControl,
	FormLabel,
	TextField,
	Box,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	Divider,
	Checkbox,
	ListItemAvatar,
	Avatar
} from "@mui/material";

function PermissionList(props) {
	const [permitidosList, setPermitidosList] = useState([]);
	const [allList, setAllList] = useState([]);
	const [searchFiltro, setSearchFiltro] = useState("");

	useEffect(() => {
		if (props.allList !== null && props.allList !== undefined) {
			setAllList(props.allList);
		}

		if (props.permitidosList !== null && props.permitidosList !== undefined) {
			setPermitidosList(props.permitidosList);
		}

		console.log('update PermissionList')
	}, [props]);

	const handleAddRemovePermitido = (checked, value) => {
		if (permitidosList === undefined || permitidosList === null) {
			setPermitidosList([]);
		}

		let tmp_permitidos_list = Object.assign([], permitidosList);

		if (!checked) 
		{
			tmp_permitidos_list = tmp_permitidos_list.filter(item => item !== value);
		}
		else 
		{
			if (!tmp_permitidos_list.includes(value)) 
			{
				tmp_permitidos_list.push(value);
			}
		}

		setPermitidosList(tmp_permitidos_list);
		props.setPermitidosListCb(tmp_permitidos_list)
	};

	let items_list = [];
	for (let item of allList) 
	{
		let value = item.value;
		let label = item.label;

		// filtro de valores
		if (searchFiltro.length > 0) {
			if (!item.toLowerCase().includes(searchFiltro.toLowerCase())) {
				continue;
			}
		}

		// se ja estiver na lista de permitidos, check
		let checked = false;
		if (permitidosList !== null && permitidosList !== undefined && permitidosList.includes(value) === true) {
			checked = true;
		}

		items_list.push(
			<ListItem
				key={value}
				secondaryAction={
					<Checkbox
						checked={checked}
						edge="end"
						onChange={(event) => handleAddRemovePermitido(event.target.checked, value)}
					/>
				}
				disablePadding
			>
				<ListItemButton>
					<ListItemText align="center" primary={label} />
				</ListItemButton>
			</ListItem>
		);
	}

	return (
		<Box sx={{ maxHeight: 300, width: 300 }}>
			<FormControl>
				<FormLabel>{props.label}</FormLabel>
				<List dense sx={{ overflow: 'auto', bgcolor: 'lightgray' }}>
					<ListItem>
						<ListItemAvatar>
							<Avatar>
								<SearchIcon />
							</Avatar>
						</ListItemAvatar>
						<TextField
							label=""
							variant="outlined"
							size="small"
							value={searchFiltro}
							onChange={(event) => setSearchFiltro(event.target.value)}
						/>
					</ListItem>
					<Divider />
					{items_list}
				</List>
			</FormControl>
		</Box>
	);
}

PermissionList.propTypes = {
	label: PropTypes.string,
	allList: PropTypes.array,
	permitidosList: PropTypes.array,
	setPermitidosListCb: PropTypes.func
};

export default PermissionList;
