import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Typography, ListItem, ListItemText } from '@mui/material';
import { FixedSizeList } from 'react-window';


function Logs(props) {
    const listRef = useRef(null);

    const renderRow = ({ index, style }) => {
        //if (rpaLogsList[index].log_type === "normal")
        return (
            <ListItem style={{ ...style, padding:0, margin: 0}} key={index}>
                <ListItemText primary={<Typography variant="subtitle2">{props.rpaLogsList[index].log}</Typography>} sx={{ bgcolor: props.rpaLogsList[index].log_color, m: 0, p: 0 }}/>
            </ListItem>
        );
    }

    useEffect(() => {
        listRef?.current?.scrollToItem(props.rpaLogsList.length);
    }, [props.rpaLogsList]);
    
    return (
        <React.Fragment>
            {props.rpaLogsList !== null && props.rpaLogsList !== undefined && props.rpaLogsList.length > 0 ?
                <FixedSizeList
                    height={350}
                    itemSize={25}
                    itemCount={props.rpaLogsList.length}
                    overscanCount={5}
                    ref={listRef}
                >
                    {renderRow}
                </FixedSizeList>
                :
                <Typography sx={{m:1}} variant="subtitle1">
                    Nenhum log disponível na data selecionada.
                </Typography>
            }
        </React.Fragment>
    );
}

Logs.propTypes = {
    rpaLogsList: PropTypes.array,
};

export default Logs;