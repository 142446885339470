import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import Loader from "../Loader";
import { socket } from "../../socket";

import {
    Dialog,
    DialogTitle,
    Typography,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Grid,
} from "@mui/material";

function AddEditEmpresaDialog(props) {
    const [loading, setLoading] = useState(false);
    const [empresaNome, setEmpresaNome] = useState("");
    const [empresaCNPJ, setEmpresaCNPJ] = useState("");

    useEffect(() => {
        function fetchEmpresaData() {
            if (props.empresaData !== undefined) {
                if (props.empresaData.nome !== undefined)
                    setEmpresaNome(props.empresaData.nome);

                if (props.empresaData.cnpj !== undefined)
                    setEmpresaCNPJ(props.empresaData.cnpj);
                    
            }
        }

        fetchEmpresaData();
    }, [props.empresaData]);

    const handleAddEditEmpresa = async () => {
        if (empresaNome.length < 4)
            return props.openSnackbar(`Nome inválido.`);

        let empresaData = {
            nome: empresaNome,
            cnpj: empresaCNPJ,
        }

        if (props.empresaData === undefined)
        {
            socket.emit("request_add_empresa", { "data": empresaData }, (res) => {
                if (res) {
                    props.openSnackbar(`Empresa criada.`);
                }
            });
        } 
        else
        {
            socket.emit("request_update_empresa", { "doc_id": props.empresaData.doc_id, "data": empresaData }, (res) => {
                if (res) {
                    props.openSnackbar(`Empresa atualizada.`);
                }
            });
        }

        // fechar dialog
        handleClose();
    };

    const handleClose = () => {
        setLoading(false);
        setEmpresaNome("");
        setEmpresaCNPJ("");
        props.handleClose(false);
    };

    // loading
    if (loading) {
        return (
            <Loader />
        );
    }

    return (
        <Dialog fullWidth maxWidth="sm" open={props.open}>
            <DialogTitle>
                <Typography>
                    {props.empresaData === undefined ?
                        "Nova Empresa"
                    :
                        `Atualizar Empresa: ${empresaNome}`
                    }
                </Typography>
            </DialogTitle>

            <DialogContent sx={{ m: 1, p: 1 }}>
                <Grid container justifyContent="center" alignItems="center" spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Nome"
                            variant="filled"
                            color="secondary"
                            value={empresaNome} 
                            onChange={ (event) => setEmpresaNome(event.target.value) }
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="CNPJ"
                            variant="filled"
                            color="secondary"
                            value={empresaCNPJ} 
                            onChange={ (event) => setEmpresaCNPJ(event.target.value) }
                        />
                    </Grid>

                </Grid>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Fechar
                </Button>
                <Button onClick={() => handleAddEditEmpresa()} color="primary">
                    {props.empresaData === undefined ?
                        "Cadastrar"
                    :
                        "Atualizar"
                    }
                </Button>
            </DialogActions>
        </Dialog>
    );
}

AddEditEmpresaDialog.propTypes = {
    openSnackbar: PropTypes.func,
    empresaData: PropTypes.object,
};

export default AddEditEmpresaDialog;