import React, { useEffect, useState } from 'react';
import Loader from "../Loader";
import makeStyles from "@mui/styles/makeStyles";
import clsx from 'clsx';
import { socket } from "../../socket";

import {
    Grid,
    Paper,
    Box,
    Typography,
    Chip,
    Divider
} from "@mui/material";

const useStyles = makeStyles({
    paperList: {
        width: '100%',
        textAlign: "center",
        display: "flex"
    },
    bgDefault: {
        background: 'linear-gradient(45deg, rgba(15, 150, 0, 0.3) 30%, rgba(15, 150, 0, 0.3) 90%)',
        boxShadow: '0px 0px 5px 2px rgba(11, 92, 2, .4)',
    }
});

function SocketStatusPage() {
    const [rpasConnectedList, setRpasConnectedList] = useState([]);
    const [loading, setLoading] = useState(false);
    const classes = useStyles();

    useEffect(() => {
        setLoading(true);
        socket.emit("request_get_rpas_connected", (response) => {
            if (response) {
                setRpasConnectedList(JSON.parse(response));
                setLoading(false);
            }
        });

        console.log('update SocketStatusPage')
    }, []);


    let elements_rpas = [];
    for (let [idx, rpaData] of rpasConnectedList.entries()) {
        elements_rpas.push(
            <Grid item key={"rpa" + rpaData.doc_id + idx} xs={6} sm={3}>
                <Paper elevation={3} square={true} sx={{ p: 1 }} className={clsx(classes.paperList, classes.bgDefault)}>
                    <Grid container justifyContent="center" alignItems="center" spacing={0}>
                        <Grid item xs={12} md={10}>
                            <Typography variant="subtitle2" component="div" sx={{ textTransform: 'uppercase' }}>
                                <Box sx={{ textAlign: 'start', boxShadow: 0, m: 0, p: 1, fontWeight: 'bold' }}>
                                    SID: <Chip sx={{ fontWeight: 'normal' }} label={rpaData.sid} size="small" variant="outlined" />
                                </Box>
                                <Divider variant="middle" />
                                <Box sx={{ textAlign: 'start', boxShadow: 0, m: 0, p: 1, fontWeight: 'bold' }}>
                                    NOME RPA: <Chip sx={{ fontWeight: 'normal' }} label={rpaData.rpa_identity} size="small" variant="outlined" />
                                </Box>
                                <Divider variant="middle" />
                                <Box sx={{ textAlign: 'start', boxShadow: 0, m: 0, p: 1, fontWeight: 'bold' }}>
                                    STATUS: <Chip sx={{ fontWeight: 'normal' }} label={rpaData.rpa_status} size="small" variant="outlined" />
                                </Box>
                                <Divider variant="middle" />
                                <Box sx={{ textAlign: 'start', boxShadow: 0, m: 0, p: 1, fontWeight: 'bold' }}>
                                    HOSTNAME: <Chip sx={{ fontWeight: 'normal' }} label={rpaData.hostname} size="small" variant="outlined" />
                                </Box>
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        );
    }

    if (loading === true) {
        return (
            <Loader />
        );
    }

    return (
        <React.Fragment>
            <Grid container justifyContent="center" alignItems="center" spacing={2}>
                <Grid item xs={12} sm={12}>
                    <Box sx={{ mt: 2, mb: 2, textAlign: 'center' }}>
                        RPA's conectados: {elements_rpas !== undefined ? elements_rpas.length : 0}
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Grid container justifyContent="center" alignItems="center" spacing={2} sx={{ p: 2 }}>
                        {elements_rpas}
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );

}

SocketStatusPage.propTypes = {
};

export default SocketStatusPage;
