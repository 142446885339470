import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import PermissionList from "../PermissionList";
import Loader from "../Loader";
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { RandomToken } from '@sibevin/random-token'
import { socket } from "../../socket";

import {
    Dialog,
    DialogTitle,
    Typography,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Grid,
    Divider,
    Fab,
    IconButton
} from "@mui/material";

function AddEditCredDialog(props) {
    const [rpasPermitidosList, setRpasPermitidosList] = useState([]);
    const [usersPermitidosList, setUsersPermitidosList] = useState([]);
    const [credId, setCredId] = useState(RandomToken.gen({ length: 32, seed: 'h' }));
    const [credCamposList, setCredCamposList] = useState([{ 'nome': "usuario", 'valor': "" }, { 'nome': "senha", 'valor': "" }]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (props.credData !== null && props.credData !== undefined) 
        {
            if (props.credData.usuarios_permitidos !== null && props.credData.usuarios_permitidos !== undefined)
                setUsersPermitidosList(props.credData.usuarios_permitidos);

            if (props.credData.rpas_permitidos !== null && props.credData.rpas_permitidos !== undefined)
                setRpasPermitidosList(props.credData.rpas_permitidos);

            if (props.credData.campos !== null && props.credData.campos !== undefined)
                setCredCamposList(props.credData.campos);

            if (props.credData.id !== null && props.credData.id !== undefined)
                setCredId(props.credData.id);
        }

    }, [props.credData]);

    const handleCadastrarCredencial = async () => {
        setLoading(true);

        let objCred = {
            id: credId,
            users_permitidos: usersPermitidosList,
            rpas_permitidos: rpasPermitidosList,
            campos: credCamposList,
            doc_id: props.credData === undefined ? undefined : props.credData.doc_id
        }

        if (props.credData === undefined) {
            socket.emit("request_create_credencial", objCred, (response) => {
                if (response) 
                {
                    props.openSnackbar(`Credencial '${objCred.id}' criada.`);
                    handleClose();
                }
                else 
                {
                    props.openSnackbar(`Não foi possível criar a credencial.`);
                }
            });
        }
        else 
        {
            socket.emit("request_update_credencial", objCred, (response) => {
                if (response) 
                {
                    props.openSnackbar(`Credencial ${objCred.id} atualizada.`);
                    handleClose();
                }
                else 
                {
                    props.openSnackbar(`Não foi possível atualizar a credencial.`);
                }
            });
        }
    };

    const handleClose = () => {
        setUsersPermitidosList([]);
        setRpasPermitidosList([]);
        setCredId("");
        setCredCamposList([]);
        setLoading(false);
        props.handleClose(false);
    };

    const handleAddParameterInicio = () => {
        var tmp_params = Object.assign([], credCamposList);
        tmp_params.push({
            'nome': "",
            'valor': ""
        });
        setCredCamposList(tmp_params);
    };

    const handleChangeNomeCredencial = (idx, value) => {
        var tmp_params = Object.assign([], credCamposList);
        tmp_params[idx].nome = value;
        setCredCamposList(tmp_params);
    };

    const handleChangeValueCredencial = (idx, value) => {
        var tmp_params = Object.assign([], credCamposList);
        tmp_params[idx].valor = value;
        setCredCamposList(tmp_params);
    };

    const handleDeleteCampoCredencial = (idx) => {
        var tmp_params = Object.assign([], credCamposList);
        tmp_params.splice(idx, 1);
        setCredCamposList(tmp_params);
    };

    let list_parametros = [];
    if (credCamposList.length > 0) {
        for (let [idx, credCampo] of credCamposList.entries()) 
        {
            list_parametros.push(
                <Grid item key={"parameter" + idx} xs={12} align="center">
                    <Grid container justifyContent="center" alignItems="center" spacing={1}>
                        <Grid item xs={2}>
                            <IconButton color="primary" sx={{ m: 0, p: 0 }} onClick={() => handleDeleteCampoCredencial(idx)}>
                                <DeleteForeverIcon sx={{ color: 'red' }} />
                            </IconButton>
                        </Grid>
                        <Grid item xs={5}>
                            <TextField
                                fullWidth
                                label="Nome"
                                variant="filled"
                                color="secondary"
                                value={credCampo.nome}
                                onChange={(event) => handleChangeNomeCredencial(idx, event.target.value)}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <TextField
                                fullWidth
                                label="Values"
                                variant="filled"
                                color="secondary"
                                value={credCampo.valor}
                                onChange={(event) => handleChangeValueCredencial(idx, event.target.value)}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            );
        }
    }

    // loading
    if (loading) {
        return (
            <Loader />
        );
    }

    return (
        <Dialog fullWidth maxWidth="md" open={props.open}>
            <DialogTitle>
                {props.credData === undefined ?
                    "Nova Credencial"
                :
                    `Atualizar Credencial: ${credId}`
                }
            </DialogTitle>

            <DialogContent sx={{ m: 1, p: 1 }}>
                <Grid container justifyContent="center" alignItems="center" spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            disabled
                            label="ID"
                            variant="filled"
                            color="secondary"
                            value={credId}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Divider light />
                    </Grid>

                    <Grid item align="center" xs={12}>
                        <Grid container justifyContent="center" alignItems="center" spacing={2}>
                            <Grid item xs={3} align="center">
                                <Typography>Campos</Typography>
                            </Grid>
                            <Grid item xs={1} align="center">
                                <Fab size="small" color="white" aria-label="add parameter" onClick={() => handleAddParameterInicio()}>
                                    <AddIcon />
                                </Fab>
                            </Grid>
                            <Grid item xs={12} align="center">
                                <Grid container justifyContent="center" alignItems="center" spacing={1}>
                                    {list_parametros}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Divider light />
                    </Grid>

                    <Grid align="center" item xs={6}>
                        <PermissionList permitidosList={rpasPermitidosList} allList={props.allRpasList.map(el => { return { "label": el.rpa_identity, "value": el.token } })} label="Permissões RPA's" setPermitidosListCb={setRpasPermitidosList}/>
                    </Grid>

                    <Grid align="center" item xs={6}>
                        <PermissionList permitidosList={usersPermitidosList} allList={props.allUsersList.map(el => { return { "label": el.user_email, "value": el.user_email }})} label="Permissões Usuários" setPermitidosListCb={setUsersPermitidosList}/>
                    </Grid>

                </Grid>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Fechar
                </Button>
                <Button onClick={() => handleCadastrarCredencial()} color="primary">
                    {props.credData === undefined ?
                        "Cadastrar"
                    :
                        "Atualizar"
                    }
                </Button>
            </DialogActions>
        </Dialog>
    );
}

AddEditCredDialog.propTypes = {
    openSnackbar: PropTypes.func,
    credData: PropTypes.object,
    allRpasList: PropTypes.array,
    allUsersList: PropTypes.array,
};

export default AddEditCredDialog;