import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from "@mui/styles/makeStyles";
import { CircularProgress } from "@mui/material";
import {
    Grid,
    Button,
} from "@mui/material";
import { socket } from "../../socket";

const useStyles = makeStyles({
    roundButton: {
        border: '1px solid',
        borderRadius: '100%',
        width: '120px',
        height: '120px',
        backgroundColor: 'rgb(11, 128, 108)',
        borderColor: 'rgb(17, 212, 178)',
    },
});

function RpaActions(props) {
    const classes = useStyles();

    const handleSendStartSignal = () => {
        socket.emit("request_start_rpa", props.rpaData.token, (res) => {
            if (res === true) {
                props.openSnackbar(`Iniciando RPA...`);
            }
            else {
                props.openSnackbar(`Não foi possível iniciar o RPA.`);
            }
        });
    };

    const handleSendStopSignal = () => {
        socket.emit("request_stop_rpa", props.rpaData.token, (res) => {
            if (res === true) {
                props.openSnackbar(`Parando RPA...`);
            }
            else {
                props.openSnackbar(`Não foi possível parar o RPA.`);
            }
        });
    };
    
    return (
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
            <Grid item xs={4} align="center">
                {(props.rpaData === null || props.rpaData === undefined) ?
                    <CircularProgress />
                :
                    <React.Fragment>
                        {(props.rpaData.rpa_status === "PARADO" || props.rpaData.rpa_status === null || props.rpaData.rpa_status === undefined) &&
                            <Button className={classes.roundButton} variant="contained" onClick={() => handleSendStartSignal()}>INICIAR RPA</Button>
                        }

                        {props.rpaData.rpa_status === "EXECUTANDO" &&
                            <Button className={classes.roundButton} variant="contained" onClick={() => handleSendStopSignal()}>PARAR RPA</Button>
                        }

                        {props.rpaData.rpa_status === "EM FILA" &&
                            <Button className={classes.roundButton} variant="contained" onClick={() => handleSendStopSignal()}>REMOVER RPA DA FILA</Button>
                        }
                    </React.Fragment>
                }
            </Grid>
        </Grid>
    );
}

RpaActions.propTypes = {
    openSnackbar: PropTypes.func,
    rpaData: PropTypes.object
};

export default RpaActions;