import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from "prop-types";
import Loader from "../Loader";
import makeStyles from "@mui/styles/makeStyles";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { socket } from "../../socket";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import AddEditCredDialog from "./AddEditCredDialog.js";


import {
    Grid,
    Paper,
    Fab,
    Box,
    Typography,
    Divider,
    Chip,
    TextField,
    FormControl,
    InputAdornment,
    IconButton
} from "@mui/material";

const useStyles = makeStyles({
    paperList: {
        width: '100%',
        textAlign: "center",
        display: "flex"
    },
    bgParado: {
        background: 'linear-gradient(45deg, rgba(242, 22, 59, 0.3) 30%, rgba(255, 0, 0, 0.25) 90%)',
        boxShadow: '0px 0px 5px 2px rgba(255, 38, 0, .4)',
    },
    bgAtivo: {
        background: 'linear-gradient(45deg, rgba(66, 237, 143, 0.3) 30%, rgba(0, 255, 4, 0.25) 90%)',
        boxShadow: '0px 0px 5px 2px rgba(0, 235, 4, .4)',
    },
    bgFila: {
        background: 'linear-gradient(45deg, rgba(235, 235, 38, 0.3) 30%, rgba(0, 255, 4, 0.25) 90%)',
        boxShadow: '0px 0px 5px 2px rgba(0, 235, 4, .4)',
    },
    search: {
        margin: "0"
    }
});

function BaseSenhasPage(props) {
    const [allUsersList, setAllUsersList] = useState([]);
    const [allCredenciaisList, setAllCredenciaisList] = useState([]);
    const [credSelectedIdx, setCredSelectedIdx] = useState(undefined);
    const [rpasList, setRpasList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [dialogAddCredOpen, setDialogAddCredOpen] = useState(false);
    const [showClearIcon, setShowClearIcon] = useState("none");
    const [searchInput, setSearchInput] = useState("");
    const classes = useStyles();

    const handleChangeSearchInput = (event) => {
        if (event.target.value === "") {
            handleClickSearch();
            setShowClearIcon("none");
        } else {
            setShowClearIcon("flex");
        }
        
        setSearchInput(event.target.value);
    };

    const handleClickClearSearch = () => {
        setSearchInput("");
        setShowClearIcon("none");
    };

    const handleClickSearch = () => {
        setLoading(true);
        socket.emit("request_get_all_credenciais", { "search": searchInput }, (args) => {
            setAllCredenciaisList(JSON.parse(args));
            setLoading(false);
        });
    };

    const handleOpenCloseDialogAddCred = (state) => {
        setCredSelectedIdx(undefined);
        setDialogAddCredOpen(state);
    }

    const handleOpenCloseDialogEditCred = (state, credIdx) => {
        setCredSelectedIdx(credIdx);
        setDialogAddCredOpen(state);
    }

    const updateInfos = useCallback(async () => {
        setLoading(true);
        socket.emit("request_get_all_users", (response) => {
            if (response) {
                setAllUsersList(JSON.parse(response));
                setLoading(false);
            }
        });

        setLoading(true);
        socket.emit("request_get_all_credenciais", (response) => {
            if (response) {
                console.log(JSON.parse(response));
                setAllCredenciaisList(JSON.parse(response));
                setLoading(false);
            }
            
        });
        
        setLoading(true);
        socket.emit("request_get_all_rpas", { "search": undefined }, (args) => {
            setRpasList(JSON.parse(args));
            setLoading(false);
        });
    }, [])

    useEffect(() => {
        setLoading(true);
        updateInfos();
        
        console.log('update BaseSenhasPage')
    }, [updateInfos]);

    let elements_creds = [];
    if (allCredenciaisList !== null && allCredenciaisList !== undefined && typeof (allCredenciaisList) === "object") {
        for (let [idx, credData] of allCredenciaisList.entries()) {
            elements_creds.push(
                <Grid item key={credData.rpa_identity} xs={6} sm={3}>
                    <Paper elevation={3} square={true} sx={{ p: 1 }}>
                        <Grid container justifyContent="center" alignItems="center" spacing={0}>
                            <Grid item xs={12} md={10}>
                                <Typography variant="subtitle2" component="div" sx={{ textTransform: 'uppercase' }}>
                                    <Box sx={{ textAlign: 'start', boxShadow: 0, m: 0, p: 1, fontWeight: 'bold' }}>
                                        ID: <Chip sx={{ fontWeight: 'normal' }} label={credData.id} size="small" variant="outlined" />
                                    </Box>
                                </Typography>
                            </Grid>

                            <Grid item xs={12} md={2}>
                                <Grid container justifyContent="center" alignItems="center" direction="column" spacing={2}>
                                    <Grid item>
                                        <Fab size="small" color="secondary" aria-label="edit" >
                                            <EditIcon onClick={() => handleOpenCloseDialogEditCred(true, idx)}/>
                                        </Fab>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            );
        }
    }

    return (
        <React.Fragment>
            <AddEditCredDialog
                open={dialogAddCredOpen} 
                handleClose={handleOpenCloseDialogAddCred}
                openSnackbar={props.openSnackbar}
                allUsersList={allUsersList}
                allRpasList={rpasList}
                credData={credSelectedIdx !== undefined ? allCredenciaisList[credSelectedIdx] : undefined}
            />

            <Grid container justifyContent="center" alignItems="center" spacing={2}>
                <Grid item xs={12} sm={12} sx={{ mt: 2, textAlign: 'center' }}>
                    <Grid container justifyContent="center" alignItems="center" spacing={2}>
                        <Grid item xs={6} sm={6}>
                            <Box sx={{ textAlign: 'center' }}>
                                <Fab color="primary" aria-label="add" onClick={() => handleOpenCloseDialogAddCred(true, undefined)}>
                                    <AddIcon />
                                </Fab>
                            </Box>
                        </Grid>

                        <Grid item xs={6} sm={6}>
                            <Box sx={{ textAlign: 'center' }}>
                                <FormControl className={classes.search}>
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        onChange={handleChangeSearchInput}
                                        value={searchInput}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start" onClick={handleClickSearch}>
                                                    {showClearIcon ? 
                                                        <IconButton onClick={() => handleClickSearch()} sx={{ m:0, p:0}}>
                                                            <SearchIcon />
                                                        </IconButton>
                                                    :
                                                        <SearchIcon />
                                                    }
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment
                                                    position="end"
                                                    style={{ display: showClearIcon }}
                                                >
                                                    <IconButton onClick={ () => handleClickClearSearch() }>
                                                        <ClearIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </FormControl>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                
                <Grid item xs={12} sm={12}>
                    {(loading === true) ?
                        < Loader />
                    :
                        <Grid container justifyContent="center" alignItems="center" spacing={2} sx={{ p: 2 }}>
                            {elements_creds}
                        </Grid>
                    }
                </Grid>
            </Grid>
        </React.Fragment>
    );

}

BaseSenhasPage.propTypes = {
    openSnackbar: PropTypes.func.isRequired
};

export default BaseSenhasPage;
