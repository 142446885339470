import React, { Component } from "react";

import PropTypes from "prop-types";

import validate from "validate.js";
import moment from "moment";

import withStyles from "@mui/styles/withStyles";

import {
  DialogContent,
  Typography,
  Box,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Hidden,
  TextField,
  Tooltip,
  IconButton,
  Divider,
} from "@mui/material";

import {
  Person as PersonIcon,
  Edit as EditIcon,
  Email as EmailIcon,
  Warning as WarningIcon,
  Check as CheckIcon,
  AccessTime as AccessTimeIcon,
} from "@mui/icons-material";

import constraints from "../../data/constraints";
import authentication from "../../services/authentication";

const styles = (theme) => ({
  dialogContent: {
    paddingTop: theme.spacing(2),
  },

  badge: {
    top: theme.spacing(2),
    right: -theme.spacing(2),
  },

  loadingBadge: {
    top: "50%",
    right: "50%",
  },

  nameInitials: {
    cursor: "default",
  },

  personIcon: {
    fontSize: theme.spacing(7),
  },

  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),

    minHeight: "initial",
  },
});

const initialState = {
  showingField: "",
  firstName: "",
  lastName: "",
  username: "",
  emailAddress: "",
  performingAction: false,
  sentVerificationEmail: false,
  errors: null,
};

class AccountTab extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;
  }

  getNameInitialsOrIcon = () => {
    const { user } = this.props;

    if (!user) {
      return null;
    }

    const { classes, userData } = this.props;

    if (!userData) {
      return <PersonIcon className={classes.personIcon} />;
    }

    const nameInitials = authentication.getNameInitials({
      ...user,
      ...userData,
    });

    if (nameInitials) {
      return (
        <Typography className={classes.nameInitials} variant="h2">
          {nameInitials}
        </Typography>
      );
    }

    return <PersonIcon className={classes.personIcon} />;
  };

  showField = (fieldId) => {
    if (!fieldId) {
      return;
    }

    this.setState({
      showingField: fieldId,
    });
  };

  hideFields = (callback) => {
    this.setState(
      {
        showingField: "",
        firstName: "",
        lastName: "",
        username: "",
        emailAddress: "",
        errors: null,
      },
      () => {
        if (callback && typeof callback === "function") {
          callback();
        }
      }
    );
  };

  changeFirstName = () => {
    const { firstName } = this.state;

    const errors = validate(
      {
        firstName: firstName,
      },
      {
        firstName: constraints.firstName,
      }
    );

    if (errors) {
      this.setState({
        errors: errors,
      });

      return;
    }

    this.setState(
      {
        errors: null,
      },
      () => {
        const { userData } = this.props;

        if (firstName === userData.firstName) {
          return;
        }

        this.setState(
          {
            performingAction: true,
          },
          () => {
            authentication
              .changeFirstName(firstName)
              .catch((reason) => {
                const code = reason.code;
                const message = reason.message;

                switch (code) {
                  default:
                    this.props.openSnackbar(message);
                    return;
                }
              })
              .finally(() => {
                this.setState({
                  performingAction: false,
                });
              });
          }
        );
      }
    );
  };

  changeLastName = () => {
    const { lastName } = this.state;

    const errors = validate(
      {
        lastName: lastName,
      },
      {
        lastName: constraints.lastName,
      }
    );

    if (errors) {
      this.setState({
        errors: errors,
      });

      return;
    }

    this.setState(
      {
        errors: null,
      },
      () => {
        const { userData } = this.props;

        if (lastName === userData.lastName) {
          return;
        }

        this.setState(
          {
            performingAction: true,
          },
          () => {
            authentication
              .changeLastName(lastName)
              .catch((reason) => {
                const code = reason.code;
                const message = reason.message;

                switch (code) {
                  default:
                    this.props.openSnackbar(message);
                    return;
                }
              })
              .finally(() => {
                this.setState({
                  performingAction: false,
                });
              });
          }
        );
      }
    );
  };

  changeUsername = () => {
    const { username } = this.state;

    const errors = validate(
      {
        username: username,
      },
      {
        username: constraints.username,
      }
    );

    if (errors) {
      this.setState({
        errors: errors,
      });

      return;
    }

    this.setState(
      {
        errors: null,
      },
      () => {
        const { userData } = this.props;

        if (username === userData.username) {
          return;
        }

        this.setState(
          {
            performingAction: true,
          },
          () => {
            authentication
              .changeUsername(username)
              .catch((reason) => {
                const code = reason.code;
                const message = reason.message;

                switch (code) {
                  default:
                    this.props.openSnackbar(message);
                    return;
                }
              })
              .finally(() => {
                this.setState({
                  performingAction: false,
                });
              });
          }
        );
      }
    );
  };

  changeEmailAddress = () => {
    const { emailAddress } = this.state;

    const errors = validate(
      {
        emailAddress: emailAddress,
      },
      {
        emailAddress: constraints.emailAddress,
      }
    );

    if (errors) {
      this.setState({
        errors: errors,
      });

      return;
    }

    this.setState(
      {
        errors: null,
      },
      () => {
        const { user } = this.props;

        if (emailAddress === user.email) {
          return;
        }

        this.setState(
          {
            performingAction: true,
          },
          () => {
            authentication
              .changeEmailAddress(emailAddress)
              .catch((reason) => {
                const code = reason.code;
                const message = reason.message;

                switch (code) {
                  default:
                    this.props.openSnackbar(message);
                    return;
                }
              })
              .finally(() => {
                this.setState({
                  performingAction: false,
                });
              });
          }
        );
      }
    );
  };

  verifyEmailAddress = () => {
    this.setState(
      {
        performingAction: true,
      },
      () => {
        authentication
          .verifyEmailAddress()
          .then(() => {
            this.setState(
              {
                sentVerificationEmail: true,
              },
              () => {
                this.props.openSnackbar("Sent verification e-mail");
              }
            );
          })
          .catch((reason) => {
            const code = reason.code;
            const message = reason.message;

            switch (code) {
              default:
                this.props.openSnackbar(message);
                return;
            }
          })
          .finally(() => {
            this.setState({
              performingAction: false,
            });
          });
      }
    );
  };

  changeField = (fieldId) => {
    switch (fieldId) {
      case "first-name":
        this.changeFirstName();
        return;

      case "last-name":
        this.changeLastName();
        return;

      case "username":
        this.changeUsername();
        return;

      case "email-address":
        this.changeEmailAddress();
        return;

      default:
        return;
    }
  };

  handleKeyDown = (event, fieldId) => {
    if (!event || !fieldId) {
      return;
    }

    if (event.altKey || event.ctrlKey || event.metaKey || event.shiftKey) {
      return;
    }

    const key = event.key;

    if (!key) {
      return;
    }

    if (key === "Escape") {
      this.hideFields();
    } else if (key === "Enter") {
      this.changeField(fieldId);
    }
  };

  handleFirstNameChange = (event) => {
    if (!event) {
      return;
    }

    const firstName = event.target.value;

    this.setState({
      firstName: firstName,
    });
  };

  handleLastNameChange = (event) => {
    if (!event) {
      return;
    }

    const lastName = event.target.value;

    this.setState({
      lastName: lastName,
    });
  };

  handleUsernameChange = (event) => {
    if (!event) {
      return;
    }

    const username = event.target.value;

    this.setState({
      username: username,
    });
  };

  handleEmailAddressChange = (event) => {
    if (!event) {
      return;
    }

    const emailAddress = event.target.value;

    this.setState({
      emailAddress: emailAddress,
    });
  };

  render() {
    // Styling
    const { classes } = this.props;

    // Properties
    const { user, userData } = this.props;

    const {
      showingField,
      performingAction,
      firstName,
      lastName,
      username,
      emailAddress,
      sentVerificationEmail,
      errors,
    } = this.state;

    const hasFirstName = userData && userData.firstName;
    const hasLastName = userData && userData.lastName;
    const hasUsername = userData && userData.username;

    return (
      <DialogContent classes={{ root: classes.dialogContent }}>
        <List disablePadding>
          <ListItem>
            {!hasFirstName && (
              <ListItemIcon>
                <Tooltip title="No first name">
                  <WarningIcon color="error" />
                </Tooltip>
              </ListItemIcon>
            )}

            {showingField === "first-name" && (
              <TextField
                autoComplete="given-name"
                autoFocus
                disabled={performingAction}
                error={!!(errors && errors.firstName)}
                fullWidth
                helperText={
                  errors && errors.firstName
                    ? errors.firstName[0]
                    : "Press Enter to change your first name"
                }
                label="First name"
                placeholder={hasFirstName && userData.firstName}
                required
                type="text"
                value={firstName}
                variant="filled"
                InputLabelProps={{ required: false }}
                onBlur={this.hideFields}
                onKeyDown={(event) => this.handleKeyDown(event, "first-name")}
                onChange={this.handleFirstNameChange}
              />
            )}

            {showingField !== "first-name" && (
              <>
                <ListItemText
                  primary="First name"
                  secondary={
                    hasFirstName
                      ? userData.firstName
                      : "You don’t have a first name"
                  }
                />

                <ListItemSecondaryAction>
                  {hasFirstName && (
                    <Tooltip title="Change">
                      <div>
                        <IconButton
                          disabled={performingAction}
                          onClick={() => this.showField("first-name")}
                          size="large"
                        >
                          <EditIcon />
                        </IconButton>
                      </div>
                    </Tooltip>
                  )}

                  {!hasFirstName && (
                    <Button
                      color="primary"
                      disabled={performingAction}
                      variant="contained"
                      onClick={() => this.showField("first-name")}
                    >
                      Add
                    </Button>
                  )}
                </ListItemSecondaryAction>
              </>
            )}
          </ListItem>

          <ListItem>
            {!hasLastName && (
              <ListItemIcon>
                <Tooltip title="No last name">
                  <WarningIcon color="error" />
                </Tooltip>
              </ListItemIcon>
            )}

            {showingField === "last-name" && (
              <TextField
                autoComplete="family-name"
                autoFocus
                disabled={performingAction}
                error={!!(errors && errors.lastName)}
                fullWidth
                helperText={
                  errors && errors.lastName
                    ? errors.lastName[0]
                    : "Press Enter to change your last name"
                }
                label="Last name"
                placeholder={hasLastName && userData.lastName}
                required
                type="text"
                value={lastName}
                variant="filled"
                InputLabelProps={{ required: false }}
                onBlur={this.hideFields}
                onKeyDown={(event) => this.handleKeyDown(event, "last-name")}
                onChange={this.handleLastNameChange}
              />
            )}

            {showingField !== "last-name" && (
              <>
                <ListItemText
                  primary="Last name"
                  secondary={
                    hasLastName
                      ? userData.lastName
                      : "You don’t have a last name"
                  }
                />

                <ListItemSecondaryAction>
                  {hasLastName && (
                    <Tooltip title="Change">
                      <div>
                        <IconButton
                          disabled={performingAction}
                          onClick={() => this.showField("last-name")}
                          size="large"
                        >
                          <EditIcon />
                        </IconButton>
                      </div>
                    </Tooltip>
                  )}

                  {!hasLastName && (
                    <Button
                      color="primary"
                      disabled={performingAction}
                      variant="contained"
                      onClick={() => this.showField("last-name")}
                    >
                      Add
                    </Button>
                  )}
                </ListItemSecondaryAction>
              </>
            )}
          </ListItem>

          <ListItem>
            {!hasUsername && (
              <ListItemIcon>
                <Tooltip title="No username">
                  <WarningIcon color="error" />
                </Tooltip>
              </ListItemIcon>
            )}

            {showingField === "username" && (
              <TextField
                autoComplete="username"
                autoFocus
                disabled={performingAction}
                error={!!(errors && errors.username)}
                fullWidth
                helperText={
                  errors && errors.username
                    ? errors.username[0]
                    : "Press Enter to change your username"
                }
                label="Username"
                placeholder={hasUsername && userData.username}
                required
                type="text"
                value={username}
                variant="filled"
                InputLabelProps={{ required: false }}
                onBlur={this.hideFields}
                onKeyDown={(event) => this.handleKeyDown(event, "username")}
                onChange={this.handleUsernameChange}
              />
            )}

            {showingField !== "username" && (
              <>
                <ListItemText
                  primary="Username"
                  secondary={
                    hasUsername
                      ? userData.username
                      : "You don’t have a username"
                  }
                />

                <ListItemSecondaryAction>
                  {hasUsername && (
                    <Tooltip title="Change">
                      <div>
                        <IconButton
                          disabled={performingAction}
                          onClick={() => this.showField("username")}
                          size="large"
                        >
                          <EditIcon />
                        </IconButton>
                      </div>
                    </Tooltip>
                  )}

                  {!hasUsername && (
                    <Button
                      color="primary"
                      disabled={performingAction}
                      variant="contained"
                      onClick={() => this.showField("username")}
                    >
                      Add
                    </Button>
                  )}
                </ListItemSecondaryAction>
              </>
            )}
          </ListItem>

          <ListItem>
            <Hidden smDown>
              <ListItemIcon>
                <EmailIcon />
              </ListItemIcon>
            </Hidden>

            {user.email && (
              <ListItemIcon>
                <>
                  {user.emailVerified && (
                    <Tooltip title="Verified">
                      <CheckIcon color="primary" />
                    </Tooltip>
                  )}

                  {!user.emailVerified && (
                    <Tooltip title="Not verified">
                      <WarningIcon color="error" />
                    </Tooltip>
                  )}
                </>
              </ListItemIcon>
            )}

            {!user.email && (
              <ListItemIcon>
                <Tooltip title="No e-mail address">
                  <WarningIcon color="error" />
                </Tooltip>
              </ListItemIcon>
            )}

            {showingField === "email-address" && (
              <TextField
                autoComplete="email"
                autoFocus
                disabled={performingAction}
                error={!!(errors && errors.emailAddress)}
                fullWidth
                helperText={
                  errors && errors.emailAddress
                    ? errors.emailAddress[0]
                    : "Press Enter to change your e-mail address"
                }
                label="E-mail address"
                placeholder={user.email}
                required
                type="email"
                value={emailAddress}
                variant="filled"
                InputLabelProps={{ required: false }}
                onBlur={this.hideFields}
                onKeyDown={(event) =>
                  this.handleKeyDown(event, "email-address")
                }
                onChange={this.handleEmailAddressChange}
              />
            )}

            {showingField !== "email-address" && (
              <>
                <ListItemText
                  primary="E-mail address"
                  secondary={
                    user.email ? user.email : "You don’t have an e-mail address"
                  }
                />

                {user.email && !user.emailVerified && (
                  <ListItemSecondaryAction sx={{ mr: 7 }}>
                    <Tooltip title="Verify">
                      <div>
                        <IconButton
                          color="secondary"
                          disabled={performingAction || sentVerificationEmail}
                          onClick={this.verifyEmailAddress}
                          size="large"
                        >
                          <CheckIcon />
                        </IconButton>
                      </div>
                    </Tooltip>
                  </ListItemSecondaryAction>
                )}

                <ListItemSecondaryAction>
                  {user.email && (
                    <Tooltip title="Change">
                      <div>
                        <IconButton
                          disabled={performingAction}
                          onClick={() => this.showField("email-address")}
                          size="large"
                        >
                          <EditIcon />
                        </IconButton>
                      </div>
                    </Tooltip>
                  )}

                  {!user.email && (
                    <Button
                      color="primary"
                      disabled={performingAction}
                      variant="contained"
                      onClick={() => this.showField("email-address")}
                    >
                      Add
                    </Button>
                  )}
                </ListItemSecondaryAction>
              </>
            )}
          </ListItem>

          <ListItem>
            <Hidden smDown>
              <ListItemIcon>
                <AccessTimeIcon />
              </ListItemIcon>
            </Hidden>

            <Hidden smDown>
              <ListItemText
                primary="Signed in"
                secondary={moment(user.metadata.lastSignInTime).format("LLLL")}
              />
            </Hidden>

            <Hidden smUp>
              <ListItemText
                primary="Signed in"
                secondary={moment(user.metadata.lastSignInTime).format("llll")}
              />
            </Hidden>
          </ListItem>

          <Box mt={1} mb={1}>
            <Divider light />
          </Box>

        </List>
      </DialogContent>
    );
  }

}

AccountTab.propTypes = {
  // Styling
  classes: PropTypes.object.isRequired,

  // Properties
  user: PropTypes.object.isRequired,
  userData: PropTypes.object,

  // Functions
  openSnackbar: PropTypes.func.isRequired,
};

export default withStyles(styles)(AccountTab);
