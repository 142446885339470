import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import Loader from "../Loader";
import makeStyles from "@mui/styles/makeStyles";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddEditAgendamento from "./AddEditAgendamento"
import ConfirmationDialog from "../ConfirmationDialog";
import clsx from 'clsx';
import { socket } from "../../socket";

import {
    Grid,
    Paper,
    Fab,
    Box,
    Typography,
    Chip,
    Divider,
    Button
} from "@mui/material";

const useStyles = makeStyles({
    paperList: {
        width: '100%',
        textAlign: "center",
        display: "flex"
    },
    bgDefault: {
        background: 'linear-gradient(45deg, rgba(15, 150, 0, 0.3) 30%, rgba(15, 150, 0, 0.3) 90%)',
        boxShadow: '0px 0px 5px 2px rgba(11, 92, 2, .4)',
    }
});

function AgendamentosPage(props) {
    const [scheduleList, setScheduleList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [dialogAgendamentoOpen, setDialogAgendamentoOpen] = useState(false);
    const [dialogDeleteAgendamentoOpen, setDialogDeleteAgendamentoOpen] = useState(false);
    const [selectedScheduleData, setSelectedScheduleData] = useState(undefined);
    const classes = useStyles();

    const handleOpenCloseDialogDeleteAgendamento = (state, scheduleData) => {
        setSelectedScheduleData(scheduleData);
        setDialogDeleteAgendamentoOpen(state);
    }

    const handleDeleteAgendamento = (scheduleData) => {
        socket.emit("request_delete_agendamento", { "doc_id": scheduleData.doc_id }, (res) => {
            if (res) 
            {
                props.openSnackbar(`Agendamento ${scheduleData.descricao} removido.`);
                socket.emit("request_update_schedules");
            }
            setDialogDeleteAgendamentoOpen(false);
        });
    }

    const handleOpenCloseDialogAgendamento = (state, scheduleData) => {
        setSelectedScheduleData(scheduleData);
        setDialogAgendamentoOpen(state);
    }

    useEffect(() => {
        socket.on("update_watch_agendamentos", (args) => {
            if (args !== null && args !== undefined) {
                setScheduleList(JSON.parse(args));
            }
            setLoading(false);
        });

        setLoading(true);
        socket.emit("request_get_agendamentos", (args) => {
            if (args !== null && args !== undefined) {
                setScheduleList(JSON.parse(args));
            }
            setLoading(false);
        });

        socket.emit("request_watch_agendamentos");
        
        console.log('update AgendamentosPage')
        return () => {
            socket.off('update_watch_agendamentos');
        };
    }, []);

    // rpas infos
    let elements_rpas = [];
    for (let [idx, scheduleData] of scheduleList.entries()) {
        elements_rpas.push(
            <Grid item key={"agendamento"+scheduleData.doc_id} xs={6} sm={3}>
                <Paper elevation={3} square={true} sx={{ p: 1 }} className={clsx(classes.paperList, classes.bgDefault)}>
                    <Grid container justifyContent="center" alignItems="center" spacing={0}>
                        <Grid item xs={12} md={10}>
                            <Typography variant="subtitle2" component="div" sx={{ textTransform: 'uppercase' }}>
                                <Box sx={{ textAlign: 'start', boxShadow: 0, m: 0, p: 1, fontWeight: 'bold' }}>
                                    AGENDAMENTO: <Chip sx={{ fontWeight: 'normal' }} label={scheduleData.descricao} size="small" variant="outlined" />
                                </Box>
                                <Divider variant="middle" />
                                <Box sx={{ textAlign: 'start', boxShadow: 0, m: 0, p: 1, fontWeight: 'bold' }}>
                                    RPA: <Chip sx={{ fontWeight: 'normal' }} label={scheduleData.rpa_data !== null ? scheduleData.rpa_data.rpa_identity : "N/A"} size="small" variant="outlined" />
                                </Box>
                                <Divider variant="middle" />
                                <Box sx={{ textAlign: 'start', boxShadow: 0, m: 0, p: 1, fontWeight: 'bold' }}>
                                    AÇÃO: <Chip sx={{ fontWeight: 'normal' }} label={scheduleData.tipo_acao} size="small" variant="outlined" />
                                </Box>
                            </Typography>
                        </Grid>

                        <Grid item xs={12} md={2}>
                            <Grid container justifyContent="center" alignItems="center" direction="column" spacing={2}>
                                <Grid item>
                                    <Fab size="small" color="secondary" aria-label="edit" onClick={() => handleOpenCloseDialogAgendamento(true, scheduleList[idx])}>
                                        <EditIcon />
                                    </Fab>
                                </Grid>
                                <Grid item>
                                    <Fab size="small" color="error" aria-label="edit" onClick={() => handleOpenCloseDialogDeleteAgendamento(true, scheduleList[idx])}>
                                        <DeleteForeverIcon />
                                    </Fab>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        );
    }

    if (loading === true) {
        return (
            <Loader />
        );
    }

    return (
        <React.Fragment>
            <AddEditAgendamento
                open={dialogAgendamentoOpen} 
                handleClose={handleOpenCloseDialogAgendamento}
                openSnackbar={props.openSnackbar}
                scheduleData={selectedScheduleData}
            />

            <ConfirmationDialog
                dialogProps={{
                    open: dialogDeleteAgendamentoOpen,
                    onClose: () => handleOpenCloseDialogDeleteAgendamento(false),
                }}
                content={<Typography variant="subtitle1" component="div" sx={{ textTransform: 'uppercase', m: 1 }}>Deseja remover o agendamento {selectedScheduleData?.descricao} ?</Typography>}
                dismissiveAction={<Button color="primary" onClick={() => handleOpenCloseDialogDeleteAgendamento(false)}>Cancelar</Button>}
                confirmingAction={<Button color="warning" onClick={() => handleDeleteAgendamento(selectedScheduleData)}>Sim</Button>}
            />

            <Grid container justifyContent="center" alignItems="center" spacing={2}>
                <Grid item xs={12} sm={12}>
                    <Box sx={{ mt: 2, mb: 2, textAlign: 'center' }}>
                        <Fab color="primary" aria-label="add" onClick={() => handleOpenCloseDialogAgendamento(true, undefined)}>
                            <AddIcon />
                        </Fab>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Grid container justifyContent="center" alignItems="center" spacing={2} sx={{ p: 2 }}>
                        {elements_rpas}
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );

}

AgendamentosPage.propTypes = {
    openSnackbar: PropTypes.func.isRequired
};

export default AgendamentosPage;
