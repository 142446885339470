import React, { Component } from "react";
import { Switch, Route } from 'react-router-dom';
import AdminUsers from "../AdminUsers";
import AdminEmpresas from "../AdminEmpresas";
import SocketStatusPage from "../SocketStatusPage";
import PropTypes from "prop-types";

class AdminPage extends Component {
  render() {
    // Functions
    const { openSnackbar, user } = this.props;

    return (
      <React.Fragment>
        <Switch>
          <Route path="/admin/users">
            <AdminUsers user={user} openSnackbar={openSnackbar} />
          </Route>
          <Route path="/admin/empresas">
            <AdminEmpresas user={user} openSnackbar={openSnackbar}/>
          </Route>
          <Route path="/admin/socketstatus">
            <SocketStatusPage />
          </Route>
        </Switch>
      </React.Fragment>
    );
  }
}

AdminPage.propTypes = {
  user: PropTypes.object.isRequired,
  openSnackbar: PropTypes.func.isRequired
};

export default AdminPage;
