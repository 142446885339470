import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from "prop-types";
import Loader from "../Loader";
import makeStyles from "@mui/styles/makeStyles";
import AddEditRpaDialog from "./AddEditRpaDialog";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DialogRpa from "../RpaDialog";
import clsx from 'clsx';
import { socket } from "../../socket";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";

import {
    Grid,
    Paper,
    Fab,
    Box,
    Typography,
    Divider,
    Chip,
    TextField,
    FormControl,
    InputAdornment,
    IconButton
} from "@mui/material";

const useStyles = makeStyles({
    paperList: {
        width: '100%',
        textAlign: "center",
        display: "flex"
    },
    bgParado: {
        background: 'linear-gradient(45deg, rgba(242, 22, 59, 0.3) 30%, rgba(255, 0, 0, 0.25) 90%)',
        boxShadow: '0px 0px 5px 2px rgba(255, 38, 0, .4)',
    },
    bgAtivo: {
        background: 'linear-gradient(45deg, rgba(66, 237, 143, 0.3) 30%, rgba(0, 255, 4, 0.25) 90%)',
        boxShadow: '0px 0px 5px 2px rgba(0, 235, 4, .4)',
    },
    bgFila: {
        background: 'linear-gradient(45deg, rgba(235, 235, 38, 0.3) 30%, rgba(0, 255, 4, 0.25) 90%)',
        boxShadow: '0px 0px 5px 2px rgba(0, 235, 4, .4)',
    },
    search: {
        margin: "0"
    }
});

function RpasPage(props) {
    const [allUsersList, setAllUsersList] = useState([]);
    const [allEmpresasList, setAllEmpresasList] = useState([]);
    const [rpaSelectedIdx, setRpaSelectedIdx] = useState(undefined);
    const [rpasList, setRpasList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [dialogAddRpaOpen, setDialogAddRpaOpen] = useState(false);
    const [dialogRpaOpen, setDialogRpaOpen] = useState(false);
    const [showClearIcon, setShowClearIcon] = useState("none");
    const [searchInput, setSearchInput] = useState("");
    const classes = useStyles();

    const handleChangeSearchInput = (event) => {
        if (event.target.value === "") {
            handleClickSearch();
            setShowClearIcon("none");
        } else {
            setShowClearIcon("flex");
        }
        
        setSearchInput(event.target.value);
    };

    const handleClickClearSearch = () => {
        setSearchInput("");
        setShowClearIcon("none");
    };

    const handleClickSearch = () => {
        setLoading(true);
        socket.emit("request_get_all_rpas", { "search": searchInput }, (args) => {
            setRpasList(JSON.parse(args));
            setLoading(false);
        });
    };

    const handleOpenCloseDialogAddRpa = (state) => {
        setRpaSelectedIdx(undefined);
        setDialogAddRpaOpen(state);
    }

    const handleOpenCloseDialogEditRpa = (state, rpaIdx) => {
        setRpaSelectedIdx(rpaIdx);
        setDialogAddRpaOpen(state);
    }

    const handleOpenCloseDialogRpa = (state, rpaIdx) => {
        setRpaSelectedIdx(rpaIdx);
        setDialogRpaOpen(state);

        if (state === false) {
            socket.emit("request_stop_watch_agendamentos");
            socket.emit("request_stop_watch_processamentos");
        }
    }

    const updateInfos = useCallback(async () => {
        setLoading(true);
        socket.emit("request_get_all_users", (response) => {
            if (response) {
                setAllUsersList(JSON.parse(response));
                setLoading(false);
            }
        });

        setLoading(true);
        socket.emit("request_get_all_empresas", (response) => {
            if (response) {
                console.log(JSON.parse(response));
                setAllEmpresasList(JSON.parse(response));
                setLoading(false);
            }
            
        });
        
        setLoading(true);
        socket.emit("request_get_all_rpas", { "search": undefined }, (args) => {
            setRpasList(JSON.parse(args));
            setLoading(false);
        });

        socket.emit("request_watch_rpas_data", { "search": undefined });
        
    }, [])

    useEffect(() => {
        setLoading(true);
        updateInfos();

        socket.on("update_watch_rpas_data", (args) => {
            setRpasList(JSON.parse(args));
        });
        
        console.log('update RpasPage')
        return () => {
            socket.off('update_watch_rpas_data');
        };
    }, [updateInfos]);

    // rpas infos
    let elements_rpas = [];
    if (rpasList !== null && rpasList !== undefined && typeof (rpasList) === "object") {
        for (let [idx, rpaData] of rpasList.entries()) {
            elements_rpas.push(
                <Grid item key={rpaData.rpa_identity} xs={6} sm={3}>
                    <Paper elevation={3} square={true} sx={{ p: 1 }} className={clsx(classes.paperList, {
                            [classes.bgParado]: rpaData.rpa_status === null,
                            [classes.bgParado]: rpaData.rpa_status === undefined,
                            [classes.bgParado]: rpaData.rpa_status === "PARADO",
                            [classes.bgAtivo]: rpaData.rpa_status === "EXECUTANDO",
                            [classes.bgFila]: rpaData.rpa_status === "EM FILA"
                        })}>

                        <Grid container justifyContent="center" alignItems="center" spacing={0}>
                            <Grid item xs={12} md={10}>
                                <Typography variant="subtitle2" component="div" sx={{ textTransform: 'uppercase' }}>
                                    <Box sx={{ textAlign: 'start', boxShadow: 0, m: 0, p: 1, fontWeight: 'bold' }}>
                                        RPA: <Chip sx={{ fontWeight: 'normal' }} label={rpaData.rpa_identity} size="small" variant="outlined" />
                                    </Box>
                                    <Divider variant="middle" />
                                    <Box sx={{ textAlign: 'start', boxShadow: 0, m: 0, p: 1, fontWeight: 'bold' }}>
                                        STATUS: <Chip sx={{ fontWeight: 'normal' }} label={rpaData.rpa_status ? rpaData.rpa_status : 'PARADO'} size="small" variant="outlined" />
                                    </Box>
                                </Typography>
                            </Grid>

                            <Grid item xs={12} md={2}>
                                <Grid container justifyContent="center" alignItems="center" direction="column" spacing={2}>
                                    {props.user && props.roles.includes("admin") &&
                                        <Grid item>
                                            <Fab size="small" color="secondary" aria-label="edit" >
                                                <EditIcon onClick={() => handleOpenCloseDialogEditRpa(true, idx)}/>
                                            </Fab>
                                        </Grid>
                                    }
                                    <Grid item>
                                        <Fab size="small" color="secondary" aria-label="edit" >
                                            <VisibilityIcon onClick={() => handleOpenCloseDialogRpa(true, idx)} />
                                        </Fab>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            );
        }
    }

    return (
        <React.Fragment>
            <AddEditRpaDialog
                open={dialogAddRpaOpen} 
                handleClose={handleOpenCloseDialogAddRpa}
                openSnackbar={props.openSnackbar}
                allUsersList={allUsersList}
                allEmpresasList={allEmpresasList}
                rpaData={rpaSelectedIdx !== undefined ? rpasList[rpaSelectedIdx] : undefined}
            />

            <DialogRpa
                open={dialogRpaOpen} 
                handleClose={handleOpenCloseDialogRpa}
                openSnackbar={props.openSnackbar}
                rpaData={rpaSelectedIdx !== undefined ? rpasList[rpaSelectedIdx] : undefined}
            />

            <Grid container justifyContent="center" alignItems="center" spacing={2}>
                {props.user && props.roles.includes("admin") &&
                    <Grid item xs={12} sm={12} sx={{ mt: 2, textAlign: 'center' }}>
                        <Grid container justifyContent="center" alignItems="center" spacing={2}>
                            <Grid item xs={6} sm={6}>
                                <Box sx={{ textAlign: 'center' }}>
                                    <Fab color="primary" aria-label="add" onClick={() => handleOpenCloseDialogAddRpa(true, undefined)}>
                                        <AddIcon />
                                    </Fab>
                                </Box>
                            </Grid>

                            <Grid item xs={6} sm={6}>
                                <Box sx={{ textAlign: 'center' }}>
                                    <FormControl className={classes.search}>
                                        <TextField
                                            size="small"
                                            variant="outlined"
                                            onChange={handleChangeSearchInput}
                                            value={searchInput}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start" onClick={handleClickSearch}>
                                                        {showClearIcon ? 
                                                            <IconButton onClick={() => handleClickSearch()} sx={{ m:0, p:0}}>
                                                                <SearchIcon />
                                                            </IconButton>
                                                        :
                                                            <SearchIcon />
                                                        }
                                                    </InputAdornment>
                                                ),
                                                endAdornment: (
                                                    <InputAdornment
                                                        position="end"
                                                        style={{ display: showClearIcon }}
                                                    >
                                                        <IconButton onClick={ () => handleClickClearSearch() }>
                                                            <ClearIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </FormControl>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                }
                
                <Grid item xs={12} sm={12}>
                    {(loading === true) ?
                        < Loader />
                    :
                        <Grid container justifyContent="center" alignItems="center" spacing={2} sx={{ p: 2 }}>
                            {elements_rpas}
                        </Grid>
                    }
                </Grid>
            </Grid>
        </React.Fragment>
    );

}

RpasPage.propTypes = {
    user: PropTypes.object.isRequired,
    openSnackbar: PropTypes.func.isRequired,
    roles: PropTypes.array.isRequired,
};

export default RpasPage;
