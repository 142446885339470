import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, Divider, Typography, TextField, Grid } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format } from 'date-fns'
import { socket } from "../../socket";
import { CircularProgress } from "@mui/material";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

function RpaProcessamentos(props) {
    const [selectedInicialDate, setSelectedInicialDate] = useState(new Date());
    const [selectedFinalDate, setSelectedFinalDate] = useState(new Date());
    const [rpaProcessamentos, setRpaProcessamentos] = useState({datasets: [], labels: []});
    const [loading, setLoading] = useState(false);

    const options = {
        responsive: true,
        scales: {
            y: {
                beginAtZero: true
            }
        }
    };

    // stream processamentos realtime
    useEffect(() => {
        socket.on("update_watch_rpa_processamentos", (args) => {
            if (args !== null && args !== undefined) {
                let data = {
                    labels: args.labels,
                    datasets: args.datasets,
                };
                setRpaProcessamentos(data);
            }
            setLoading(false);
        });
        
        if (props.rpaData !== null && props.rpaData !== undefined && props.rpaData.doc_id !== undefined) {
            setLoading(true);
            socket.emit("request_get_rpa_processamentos", { 'doc_rpa': props.rpaData.doc_id, 'data_processamento_inicial': format(selectedInicialDate, 'dd/MM/yyyy'), 'data_processamento_final': format(selectedFinalDate, 'dd/MM/yyyy') }, (args) => {
                if (args !== null && args !== undefined) {
                    let data = {
                        labels: args.labels,
                        datasets: args.datasets,
                    };
                    setRpaProcessamentos(data);
                }
                setLoading(false);
            });

            socket.emit("request_watch_rpa_processamentos", { 'doc_rpa': props.rpaData.doc_id, 'data_processamento_inicial': format(selectedInicialDate, 'dd/MM/yyyy'), 'data_processamento_final': format(selectedFinalDate, 'dd/MM/yyyy') });
        }

        console.log('update RpaProcessamentos');
        return () => {
            socket.off('update_watch_rpa_processamentos');
        };
    }, [props.rpaData, selectedInicialDate, selectedFinalDate]);

    const handleUpdateSelectedInitialDate = (newValue) => {
        setSelectedInicialDate(newValue);
        setLoading(true);
    };

    const handleUpdateSelectedFinalDate = (newValue) => {
        setSelectedFinalDate(newValue);
        setLoading(true);
    };

    return (
        <Card
            sx={{
                m: 0,
                p: 0,
                border: '1px solid',
                borderColor: 'rgb(22, 219, 101)',
                boxShadow: '3px 3px 8px 3px rgb(32 40 45 / 15%)'
            }}
        >
            <CardContent>
                <Grid container justifyContent="center" alignItems="center" spacing={1}>
                    <Grid item xs={6} align="end">
                        <Typography variant="h6">Processamentos - </Typography>
                    </Grid>
                    <Grid item xs={6} align="start">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="Data Inicial"
                                inputFormat="dd/MM/yyyy"
                                value={selectedInicialDate}
                                onChange={(newValue) => {
                                    handleUpdateSelectedInitialDate(newValue);
                                }}
                                renderInput={(params) => <TextField size="small" {...params} />}
                            />
                            <DatePicker
                                label="Data Final"
                                inputFormat="dd/MM/yyyy"
                                value={selectedFinalDate}
                                onChange={(newValue) => {
                                    handleUpdateSelectedFinalDate(newValue);
                                }}
                                renderInput={(params) => <TextField size="small" {...params} />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} align="end">
                        <Divider />
                    </Grid>
                    <Grid item xs={12} align="center" sx={{ height: '400px' }}>
                        {(loading === true || props.rpaData === null || props.rpaData === undefined || rpaProcessamentos.datasets === undefined) ?
                            <CircularProgress />
                        :
                            <Bar options={options} data={rpaProcessamentos} />
                        }
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

RpaProcessamentos.propTypes = {
    rpaData: PropTypes.object,
};

export default RpaProcessamentos;