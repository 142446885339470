import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import PermissionList from "../PermissionList";
import Loader from "../Loader";
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { RandomToken } from '@sibevin/random-token'
import { socket } from "../../socket";

import {
    Dialog,
    DialogTitle,
    Typography,
    DialogContent,
    DialogActions,
    Button,
    FormControl,
    FormLabel,
    RadioGroup,
    Radio,
    TextField,
    FormControlLabel,
    Grid,
    Select,
    MenuItem,
    InputLabel,
    Divider,
    Tooltip,
    Fab,
    IconButton,
    Checkbox
} from "@mui/material";

function AddEditRpaDialog(props) {
    const [empresasPermitidasList, setEmpresasPermitidasList] = useState([]);
    const [usersPermitidosList, setUsersPermitidosList] = useState([]);
    const [rpaNome, setRpaNome] = useState("");
    const [rpaLinkRepositorio, setRpaLinkRepositorio] = useState("");
    const [rpaToken, setRpaToken] = useState(RandomToken.gen({ length: 48, seed: 'h' }));
    const [rpaPodeRodarParalelo, setRpaPodeRodarParalelo] = useState('false');
    const [rpaParametersList, setRpaParametersList] = useState([]);
    const [rpaHabilitado, setRpaHabilitado] = useState(true);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        function fetchRpaData() {
            if (props.rpaData !== null && props.rpaData !== undefined) 
            {
                if (props.rpaData.users_permitidos !== null && props.rpaData.users_permitidos !== undefined)
                    setUsersPermitidosList(props.rpaData.users_permitidos);
        
                if (props.rpaData.empresas_permitidas !== null && props.rpaData.empresas_permitidas !== undefined)
                    setEmpresasPermitidasList(props.rpaData.empresas_permitidas);
        
                if (props.rpaData.rpa_identity !== null && props.rpaData.rpa_identity !== undefined)
                    setRpaNome(props.rpaData.rpa_identity);
                
                if (props.rpaData.link_repositorio !== null && props.rpaData.link_repositorio !== undefined)
                    setRpaLinkRepositorio(props.rpaData.link_repositorio);
                
                if (props.rpaData.pode_rodar_paralelo !== null && props.rpaData.pode_rodar_paralelo !== undefined)
                    setRpaPodeRodarParalelo(props.rpaData.pode_rodar_paralelo);

                if (props.rpaData.parametros_inicio !== null && props.rpaData.parametros_inicio !== undefined)
                    setRpaParametersList(props.rpaData.parametros_inicio);

                if (props.rpaData.token !== null && props.rpaData.token !== undefined)
                    setRpaToken(props.rpaData.token);

                if (props.rpaData.rpa_habilitado !== null && props.rpaData.rpa_habilitado !== undefined)
                    setRpaHabilitado(props.rpaData.rpa_habilitado);
            }
        }

        fetchRpaData();
    }, [props.rpaData]);

    // const convertNomeEmpresaToId = (nomesEmpresasPermitidas) => {
    //     let tmpEmpresasList = [];
    //     for (let empresa_nome of nomesEmpresasPermitidas) {
    //         let empresa_data = props.allEmpresasList.find((x) => x.nome === empresa_nome);
    //         tmpEmpresasList.append(empresa_data.doc_id);
    //     }

    //     setEmpresasPermitidasList(tmpEmpresasList);
    // }

    const handleCadastrarRpa = async () => {
        if (!socket.connected)
            return props.openSnackbar(`Servidor desconectado.`);

        if (rpaNome.length < 4)
            return props.openSnackbar(`Nome inválido.`);

        if (rpaLinkRepositorio.length < 4)
            return props.openSnackbar(`Link repositório inválido.`);

        setLoading(true);

        let objRpa = {
            rpa_identity: rpaNome,
            link_repositorio: rpaLinkRepositorio,
            users_permitidos: usersPermitidosList,
            empresas_permitidas: empresasPermitidasList,
            pode_rodar_paralelo: Boolean(rpaPodeRodarParalelo),
            parametros_inicio: rpaParametersList,
            rpa_habilitado: rpaHabilitado,
            token: rpaToken,
            doc_id: props.rpaData === undefined ? undefined : props.rpaData.doc_id
        }

        if (props.rpaData === undefined) {
            socket.emit("request_create_rpa", objRpa, (response) => {
                if (response) {
                    props.openSnackbar(`RPA ${objRpa.rpa_identity} criado, token: ${objRpa.token}.`);
                    handleClose();
                }

                else {
                    props.openSnackbar(`Não foi possível criar o RPA.`);
                }
            });
        }
        else 
        {
            socket.emit("request_update_rpa", objRpa, (response) => {
                if (response) {
                    props.openSnackbar(`RPA ${objRpa.rpa_identity} atualizado.`);
                    handleClose();
                }

                else {
                    props.openSnackbar(`Não foi possível atualizar o RPA.`);
                }
            });
        }
    };

    const handleClose = () => {
        setRpaToken("");
        setRpaNome("");
        setRpaLinkRepositorio("");
        setRpaPodeRodarParalelo('false');
        setRpaParametersList([]);
        setEmpresasPermitidasList([]);
        setUsersPermitidosList([]);
        setRpaHabilitado(true);
        setLoading(false);
        props.handleClose(false);
    };

    const handleAddParameterInicio = () => {
        var tmp_params = Object.assign([], rpaParametersList);
        tmp_params.push({
            'nome': "",
            'tipo': "SELECT",
            'value': "",
            'values': []
        });
        setRpaParametersList(tmp_params);
    };

    const handleChangeTipoParameterInicio = (idx, value) => {
        var tmp_params = Object.assign([], rpaParametersList);
        tmp_params[idx].tipo = value;
        setRpaParametersList(tmp_params);
    };

    const handleChangeNomeParameterInicio = (idx, value) => {
        var tmp_params = Object.assign([], rpaParametersList);
        tmp_params[idx].nome = value;
        setRpaParametersList(tmp_params);
    };

    const handleChangeValuesParameterInicio = (idx, value) => {
        var tmp_params = Object.assign([], rpaParametersList);
        let values = value.replaceAll(" ", "").split(",")
        tmp_params[idx].values = values;
        setRpaParametersList(tmp_params);
    };

    const handleDeleteParameteInicio = (idx) => {
        var tmp_params = Object.assign([], rpaParametersList);
        tmp_params.splice(idx, 1);
        setRpaParametersList(tmp_params);
    };

    let list_parametros = [];
    for (let [idx, rpaParameter] of rpaParametersList.entries()) {
        list_parametros.push(
            <Grid item key={"parameter" + idx} xs={12} align="center">
                <Grid container justifyContent="center" alignItems="center" spacing={1}>
                    <Grid item xs={1}>
                        <IconButton color="primary" sx={{ m: 0, p: 0 }} onClick={ () => handleDeleteParameteInicio(idx) }> 
                            <DeleteForeverIcon sx={{ color: 'red' }}/>
                        </IconButton>
                    </Grid>
                    <Grid item key={"params" + idx} xs={2}>
                        <FormControl fullWidth>
                            <InputLabel id="select-tipo-param">Tipo</InputLabel>
                            <Select
                                labelId="select-tipo-param"
                                id="select-tipo-param"
                                label="Tipo"
                                value={rpaParameter.tipo}
                                onChange={(event) => handleChangeTipoParameterInicio(idx, event.target.value)}
                            >
                                <MenuItem value={"SELECT"}>SELECT</MenuItem>
                                <MenuItem value={"TEXTFIELD"}>TEXTFIELD</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            fullWidth
                            label="Nome"
                            variant="filled"
                            color="secondary"
                            value={rpaParameter.nome} 
                            onChange={ (event) => handleChangeNomeParameterInicio(idx, event.target.value) }
                        />
                    </Grid>
                    { rpaParameter.tipo === "SELECT" &&
                        <Grid item xs={5}>
                            <TextField
                                fullWidth
                                label="Values"
                                variant="filled"
                                color="secondary"
                                value={rpaParameter.values.join(', ')} 
                                onChange={ (event) => handleChangeValuesParameterInicio(idx, event.target.value) }
                            />
                        </Grid>
                    }
                </Grid>
            </Grid>
        );
    }

    // loading
    if (loading) {
        return (
            <Loader />
        );
    }

    return (
        <Dialog fullWidth maxWidth="md" open={props.open}>
            <DialogTitle>
                {props.rpaData === undefined ?
                    "Novo RPA"
                :
                    `Atualizar RPA: ${rpaNome}`
                }
            </DialogTitle>

            <DialogContent sx={{ m: 1, p: 1 }}>
                <Grid container justifyContent="center" alignItems="center" spacing={2}>

                    <Grid item xs={12}>
                        <Grid container justifyContent="center" alignItems="center" spacing={1}>
                            <Grid item>
                                <Typography sx={{ m: 0, p: 0 }}>Habilitado: </Typography>
                            </Grid>
                            <Grid item>
                                <Checkbox
                                    sx={{ m: 0, p: 0 }}
                                    checked={rpaHabilitado}
                                    onChange={(event) => setRpaHabilitado(event.target.checked)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Nome"
                            variant="filled"
                            color="secondary"
                            value={rpaNome} 
                            onChange={ (event) => setRpaNome(event.target.value) }
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Link repositório (Git)"
                            variant="filled"
                            color="secondary"
                            value={rpaLinkRepositorio}
                            type="url"
                            onChange={(event) => setRpaLinkRepositorio(event.target.value) }
                        />
                    </Grid>
                    
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            disabled
                            label="Token (RPA)"
                            variant="filled"
                            color="secondary"
                            value={rpaToken}
                        />
                    </Grid>

                    <Grid item xs={4} align="center">
                        <FormControl fullWidth sx={{ alignItems: 'center', justifyContent: 'center' }}>
                            <Tooltip title="Permitir rodar junto com outros RPA's na mesma maquina">
                                <FormLabel component="legend" id="r-pode-rodar-paralelo">Pode rodar em paralelo ?</FormLabel>
                            </Tooltip>
                            <RadioGroup row aria-labelledby="r-pode-rodar-paralelo" value={rpaPodeRodarParalelo} onChange={ (event) => setRpaPodeRodarParalelo(event.target.value) }>
                                <FormControlLabel value={true} control={<Radio /> } label="SIM" />
                                <FormControlLabel value={false} control={<Radio />} label="NÃO" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <Divider light />
                    </Grid>

                    <Grid item align="center" xs={12}>
                        <Grid container justifyContent="center" alignItems="center" spacing={2}>
                            <Grid item xs={3} align="center">
                                <Typography>Parâmetros de início</Typography>
                            </Grid>
                            <Grid item xs={1} align="center">
                                <Fab size="small" color="white" aria-label="add parameter" onClick={() => handleAddParameterInicio()}>
                                    <AddIcon />
                                </Fab>
                            </Grid>
                            <Grid item xs={12} align="center">
                                <Grid container justifyContent="center" alignItems="center" spacing={1}>
                                    {list_parametros}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Divider light />
                    </Grid>

                    <Grid align="center" item xs={6}>
                        <PermissionList permitidosList={empresasPermitidasList} allList={props.allEmpresasList.map(el => { return {"label": el.nome, "value": el.doc_id} })} label="Permissões Empresas" setPermitidosListCb={setEmpresasPermitidasList}/>
                    </Grid>

                    <Grid align="center" item xs={6}>
                        <PermissionList permitidosList={usersPermitidosList} allList={props.allUsersList.map(el => { return { "label": el.user_email, "value": el.user_email }})} label="Permissões Usuários" setPermitidosListCb={setUsersPermitidosList}/>
                    </Grid>

                </Grid>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Fechar
                </Button>
                <Button onClick={() => handleCadastrarRpa()} color="primary">
                    {props.rpaData === undefined ?
                        "Cadastrar"
                    :
                        "Atualizar"
                    }
                </Button>
            </DialogActions>
        </Dialog>
    );
}

AddEditRpaDialog.propTypes = {
    openSnackbar: PropTypes.func,
    rpaData: PropTypes.object,
    allEmpresasList: PropTypes.array,
    allUsersList: PropTypes.array,
};

export default AddEditRpaDialog;