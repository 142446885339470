import React, { Component } from "react";

import PropTypes from "prop-types";

import { BrowserRouter, Switch, Redirect, Route } from "react-router-dom";

import RpasPage from "../RpasPage";
import AgendamentosPage from "../AgendamentosPage";
import AdminPage from "../AdminPage";
import NotFoundPage from "../NotFoundPage";
import LoginPage from "../LoginPage";
import BaseSenhasPage from "../BaseSenhasPage";

class Router extends Component {
  render() {
    // Properties
    const { user, roles, bar } = this.props;

    // Functions
    const { openSnackbar } = this.props;

    return (
      <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
        {user && bar}

        <Switch>
          <Route path="/login" exact>
            {!user ? (
              <LoginPage user={user} openSnackbar={openSnackbar} />
            ) : (
              <Redirect to="/" />
            )}
          </Route>

          <Route path="/" exact>
            {user ? (
              <RpasPage user={user} roles={roles} openSnackbar={openSnackbar} />
            ) : (
              <Redirect to="/login" />
            )}
          </Route>

          <Route path="/agendamentos" exact>
            {user ? (
              <AgendamentosPage openSnackbar={openSnackbar} />
            ) : (
              <Redirect to="/" />
            )}
          </Route>

          <Route path="/credenciais" exact>
            {user ? (
              <BaseSenhasPage openSnackbar={openSnackbar} />
            ) : (
              <Redirect to="/" />
            )}
          </Route>

          <Route path="/admin">
            {user && roles.includes("admin") ? (
              <AdminPage user={user} openSnackbar={openSnackbar}/>
            ) : (
              <Redirect to="/" />
            )}
          </Route>

          <Route>
            <NotFoundPage />
          </Route>
        </Switch>
      </BrowserRouter>
    );
  }
}

Router.propTypes = {
  // Properties
  user: PropTypes.object,
  roles: PropTypes.array.isRequired,
  bar: PropTypes.element,

  // Functions
  openSnackbar: PropTypes.func.isRequired,
};

export default Router;
