import React from 'react';
import PropTypes from "prop-types";
import {
	TextField,
	Grid,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Tooltip
} from "@mui/material";

function RpaParametersList(props) {
	const handleChangeValueParameterInicio = (idx, value) => {
		var tmp_params = Object.assign([], props.parametros_inicio);
		tmp_params[idx].value = value;
		props.setParametrosInicioCb(tmp_params);
	};

	let list_parametros = [];
	if (props.parametros_inicio !== null && props.parametros_inicio !== undefined) {
		for (let [idx, rpaParameter] of props.parametros_inicio.entries()) {
			list_parametros.push(
				<Grid item key={"rpaParameter" + idx} xs={3} align="center">
					{rpaParameter.tipo === "SELECT" &&
						<FormControl fullWidth>
							<Tooltip title={rpaParameter.nome.toUpperCase()}>
								<InputLabel id={"select-param-value-" + idx}>{rpaParameter.nome.toUpperCase()}</InputLabel>
							</Tooltip>
							<Select
								size="small"
								labelId={"select-param-value-" + idx}
								label={rpaParameter.nome}
								value={rpaParameter.value}
								onChange={(event) => handleChangeValueParameterInicio(idx, event.target.value)}
							>
								{
									rpaParameter.values.map((value) => {
										return (<MenuItem key={value} value={value}>{value}</MenuItem>);
									})
								}
							</Select>
						</FormControl>
					}
					{rpaParameter.tipo === "TEXTFIELD" &&
						<TextField
							fullWidth
							size="small"
							label={rpaParameter.nome}
							variant="filled"
							color="secondary"
							value={rpaParameter.value}
							onChange={(event) => handleChangeValueParameterInicio(idx, event.target.value)}
						/>
					}
				</Grid>
			);
		}
	}

	return (
		<Grid container justifyContent="center" alignItems="center" spacing={1}>
			{list_parametros}
		</Grid>
	);
}

RpaParametersList.propTypes = {
	parametros_inicio: PropTypes.array,
	setParametrosInicioCb: PropTypes.func.isRequired
};

export default RpaParametersList;
