import React, { Component } from "react";

import PropTypes from "prop-types";

import { Hidden } from "@mui/material";

import SettingsDialog from "../SettingsDialog";
import AlertDialog from "../AlertDialog";

class DialogHost extends Component {
  render() {
    // Properties
    const { performingAction, theme, user, userData, dialogs } = this.props;

    // Functions
    const { openSnackbar } = this.props;

    const settingsDialog = dialogs.settingsDialog;
    const signOutDialog = dialogs.signOutDialog;

    return (
      <>
        {user && (
          <>
            <AlertDialog
              dialogProps={signOutDialog.dialogProps}
              performingAction={performingAction}
              theme={theme}
              user={user}
              userData={userData}
              openSnackbar={openSnackbar}
              {...signOutDialog.props}
            />
          </>
        )}

        <Hidden mdDown>
          {user && (
            <>
              <SettingsDialog
                dialogProps={settingsDialog.dialogProps}
                performingAction={performingAction}
                theme={theme}
                user={user}
                userData={userData}
                openSnackbar={openSnackbar}
                {...settingsDialog.props}
              />
            </>
          )}
        </Hidden>

        <Hidden mdUp>
          {user && (
            <>
              <SettingsDialog
                dialogProps={{
                  fullScreen: true,

                  ...settingsDialog.dialogProps,
                }}
                performingAction={performingAction}
                theme={theme}
                user={user}
                userData={userData}
                openSnackbar={openSnackbar}
                {...settingsDialog.props}
              />
            </>
          )}
        </Hidden>
      </>
    );
  }
}

DialogHost.propTypes = {
  // Properties
  performingAction: PropTypes.bool.isRequired,
  theme: PropTypes.object.isRequired,
  user: PropTypes.object,
  userData: PropTypes.object,
  dialogs: PropTypes.object.isRequired,

  // Functions
  openSnackbar: PropTypes.func.isRequired,
};

export default DialogHost;
