import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from "prop-types";
import RpaParametersList from "../RpaParametersList";
import { ReQuartzCron, Tab } from '@sbzen/re-cron';
import Loader from "../Loader";
import './styles.css';
import { socket } from "../../socket";

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    FormControl,
    Grid,
    Select,
    MenuItem,
    InputLabel,
    Divider,
    TextField
} from "@mui/material";

function AddEditAgendamento(props) {
    const [rpaParametersList, setRpaParametersList] = useState([]);
    const [rpasList, setRpasList] = useState([]);
    const [cronValue, setCronValue] = useState("* * * * * * *");
    const [activeTabValue, setActiveTabValue] = useState("MINUTES");
    const [descricao, setDescricao] = useState("");
    const [tipoAcao, setTipoAcao] = useState("INICIAR");
    const [rpaSelectedToken, setRpaSelectedToken] = useState("default");
    const [loading, setLoading] = useState(false);

    const handleChangeSelectedRpa = (value) => {
        let idx_rpa_data = rpasList.findIndex((x) => x.token === value);
        if (idx_rpa_data >= 0) {
            let rpa_data = rpasList[idx_rpa_data];
            setRpaSelectedToken(value);
            setRpaParametersList(rpa_data.parametros_inicio)
        }
    }

    const updateInfos = useCallback(async () => {
        socket.emit("request_get_all_rpas", (response) => {
            if (response) {
                setRpasList(JSON.parse(response));
            }
        });
    }, [])

    useEffect(() => {
        setLoading(true);

        function fetchScheduleData() {
            if (props.scheduleData !== undefined) {
                if (props.scheduleData.cron_value !== undefined)
                    setCronValue(props.scheduleData.cron_value);

                if (props.scheduleData.descricao !== undefined)
                    setDescricao(props.scheduleData.descricao);

                if (props.scheduleData.tipo_acao !== undefined)
                    setTipoAcao(props.scheduleData.tipo_acao);

                if (props.scheduleData.parametros_inicio !== undefined)
                    setRpaParametersList(props.scheduleData.parametros_inicio);

                if (props.scheduleData.active_tab !== undefined)
                    setActiveTabValue(props.scheduleData.active_tab);

                if (props.scheduleData.rpa_token !== undefined)
                    setRpaSelectedToken(props.scheduleData.rpa_token);
            }
        }

        updateInfos();
        fetchScheduleData();
        console.log('update AddEditAgendamento');
        setLoading(false);
    }, [props.scheduleData, updateInfos]);

    const handleChangeCron = (value) => {
        setCronValue(value);
    };

    const handleClose = () => {
        setCronValue("* * * * * * *");
        setDescricao("");
        setTipoAcao("INICIAR");
        setRpaParametersList([]);
        setActiveTabValue("MINUTES");
        setRpaSelectedToken("default");
        props.handleClose(false);
    };

    const handleCadastrarAgendamento = async () => {
        if (rpaSelectedToken === undefined || rpaSelectedToken === "default")
            return props.openSnackbar('Selecione um RPA.');

        if (cronValue.length === 0)
            return props.openSnackbar('Dados agendamento inválido.');

        if (descricao.length === 0)
            return props.openSnackbar('Digite uma descrição para o agendamento');

        if (props.scheduleData !== undefined && props.scheduleData.rpa_token === undefined)
            return props.openSnackbar('Agendamento inválido.');

        let scheduleData = {
            rpa_token: rpaSelectedToken,
            tipo_acao: tipoAcao,
            cron_value: cronValue,
            parametros_inicio: rpaParametersList,
            descricao: descricao,
            active_tab: activeTabValue
        }

        if (props.scheduleData === undefined)
        {
            socket.emit("request_add_agendamento", { "data": scheduleData }, (res) => {
                if (res) {
                    props.openSnackbar(`Agendamento criado.`);
                }
            });
        } 
        else
        {
            socket.emit("request_update_agendamento", { "doc_id": props.scheduleData.doc_id, "data": scheduleData }, (res) => {
                if (res) {
                    props.openSnackbar(`Agendamento atualizado.`);
                }
            });
        }

        handleClose();
    };

    // rpas select
    let elements_rpas_list = [];
    for (let rpaData of rpasList) {
        elements_rpas_list.push(
            <MenuItem key={rpaData.token} value={rpaData.token}>{rpaData.rpa_identity}</MenuItem>
        );
    }

    if (loading === true) {
        return (
            <Loader />
        );
    }

    return (
        <Dialog fullWidth maxWidth="lg" open={props.open} sx={{ minHeight: '10hmin' }} scroll={'body'}>
            <DialogTitle>
                {props.scheduleData === undefined ?
                    "Novo Agendamento"
                    :
                    `Atualizar Agendamento: ${descricao}`
                }
            </DialogTitle>

            <DialogContent>
                <Grid container justifyContent="center" alignItems="center" spacing={3} sx={{ mt: '0.1vmin' }}>
                    <Grid item align="center" xs={12}>
                        <TextField
                            size="medium"
                            fullWidth
                            type="text"
                            label="Descrição"
                            value={descricao}
                            onChange={(event) => setDescricao(event.target.value)}
                        />
                    </Grid>

                    <Grid item align="center">
                        <FormControl>
                            <InputLabel id="select-rpa">RPA</InputLabel>
                            <Select
                                fullWidth
                                labelId="select-rpa"
                                id="select-rpa"
                                label="RPA"
                                value={rpaSelectedToken}
                                onChange={(event) => handleChangeSelectedRpa(event.target.value)}
                            >
                                <MenuItem disabled value={"default"}>Selecione</MenuItem>
                                {elements_rpas_list}
                            </Select>
                        </FormControl>
                    </Grid>

                    {rpaParametersList !== undefined && rpaParametersList !== null && rpaParametersList.length > 0 &&
                        <Grid item align="center" xs={4}>
                            <RpaParametersList
                                parametros_inicio={rpaParametersList}
                                setParametrosInicioCb={setRpaParametersList}
                            />
                        </Grid>
                    }

                    <Grid item align="center">
                        <FormControl>
                            <InputLabel id="tipo-acao">AÇÃO</InputLabel>
                            <Select
                                fullWidth
                                labelId="tipo-acao"
                                label="AÇÃO"
                                value={tipoAcao}
                                onChange={(event) => setTipoAcao(event.target.value)}
                            >
                                <MenuItem value={"INICIAR"}>INICIAR RPA</MenuItem>
                                <MenuItem value={"PARAR"}>PARAR RPA</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <Divider light />
                    </Grid>

                    <Grid item align="center" xs={12}>
                        <ReQuartzCron
                            tabs={[Tab.SECONDS, Tab.MINUTES, Tab.HOURS, Tab.DAY, Tab.MONTH, Tab.YEAR]}
                            value={cronValue}
                            activeTab={activeTabValue}
                            onChange={(value) => handleChangeCron(value)}
                            onTabChange={(tab) => setActiveTabValue(tab)}
                        />
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Fechar
                </Button>
                <Button onClick={() => handleCadastrarAgendamento()} color="primary">
                    {props.scheduleData === undefined ?
                        "Cadastrar"
                        :
                        "Atualizar"
                    }
                </Button>
            </DialogActions>
        </Dialog>
    );
}

AddEditAgendamento.propTypes = {
    openSnackbar: PropTypes.func.isRequired,
    scheduleData: PropTypes.object,
    handleClose: PropTypes.func,
    open: PropTypes.bool
};

export default AddEditAgendamento;