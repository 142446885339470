import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, Divider, Typography, TextField, Grid } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format } from 'date-fns'
import { socket } from "../../socket";
import Logs from "./Logs";
import { CircularProgress } from "@mui/material";

function RpaLogs(props) {
    const [logSelectedDate, setLogSelectedDate] = useState(new Date());
    const [rpaLogsList, setRpaLogsList] = useState(undefined);
    const [loading, setLoading] = useState(false);

    // stream logs realtime
    useEffect(() => {
        socket.on("update_watch_rpa_logs", (args) => {
            if (args !== null && args !== undefined) {
                setRpaLogsList(JSON.parse(args));
            }
            setLoading(false);
        });
        
        if (props.rpaData !== null && props.rpaData !== undefined && props.rpaData.doc_id !== undefined) {
            setLoading(true);
            socket.emit("request_get_rpa_logs", { 'doc_rpa': props.rpaData?.doc_id, 'data_log': format(logSelectedDate, 'dd/MM/yyyy') }, (args) => {
                if (args !== null && args !== undefined) {
                    setRpaLogsList(JSON.parse(args));
                }
                setLoading(false);
            });

            socket.emit("request_watch_rpa_logs", { 'doc_rpa': props.rpaData?.doc_id, 'data_log': format(logSelectedDate, 'dd/MM/yyyy') });
        }
        
        console.log('update RpaLogs');
        return () => {
            socket.off('update_watch_rpa_logs');
        };
    }, [props.rpaData, logSelectedDate]);

    const handleUpdateSelectedDate = (newValue) => {
        setLogSelectedDate(newValue);
        setLoading(true);
    };

    return (
        <Card
            sx={{
                m: 0,
                p: 0,
                border: '1px solid',
                borderColor: 'rgb(22, 219, 101)',
                boxShadow: '3px 3px 8px 3px rgb(32 40 45 / 15%)'
            }}
        >
            <CardContent>
                <Grid container justifyContent="center" alignItems="center" spacing={1}>
                    <Grid item xs={6} align="end">
                        <Typography variant="h6">Logs - </Typography>
                    </Grid>
                    <Grid item xs={6} align="start">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="Data Logs"
                                inputFormat="dd/MM/yyyy"
                                value={logSelectedDate}
                                onChange={(newValue) => { handleUpdateSelectedDate(newValue); }}
                                renderInput={(params) => <TextField size="small" {...params} />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} align="end">
                        <Divider />
                    </Grid>
                    <Grid item xs={12} align="center">
                        {(loading === true || props.rpaData === null || props.rpaData === undefined || rpaLogsList === undefined) ?
                            <CircularProgress />
                        :
                            <Logs rpaLogsList={rpaLogsList}/>
                        }
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

RpaLogs.propTypes = {
    rpaData: PropTypes.object,
};

export default RpaLogs;